import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AppState } from "../../../context/stores/rootReducers";
import { useEffect } from "react";
import { actions } from "../../../context/stores/rootActions";
import { eBaseActionStatus } from "../../../context/base/eBaseActionStatus";

export function useLookupCollectionType() {
	const dispatch = useDispatch();
	const { status, allCollectionsType } = useSelector((state: AppState) => state.collectionType);
	useEffect(() => {
		if (status === eBaseActionStatus.idle || status === eBaseActionStatus.reload) dispatch(actions.collectionType.getAllRequest());
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return { collection_type_all: allCollectionsType };
}
