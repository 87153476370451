import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	BaseForm,
	ButtonCancel,
	ButtonSave,
	FormGroup,
	ModalBody,
	ModalDraggable,
	ModalFooter,
	ModalHeader,
	NumberBox,
	SwitchBox,
	TextBox,
} from '../../components/base';

import { BannerPositionModel, dfBannerPositionModel, validBannerPositionSchema } from '../../context/models/banners/BannerPositionModel';

type IBannerPositionDetailModalProps = {
	isShow: boolean;
	title: string;
	detailModel?: BannerPositionModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: BannerPositionModel) => void;
};

const BannerPositionDetail = (props: IBannerPositionDetailModalProps) => {
	const { isShow, title, detailModel, onSubmit, onClose, isSaving } = props;
	const isAddMode = !detailModel;
	const [initialValues, setInitialValues] = useState<BannerPositionModel>(dfBannerPositionModel);

	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<BannerPositionModel>({
		resolver: yupResolver(validBannerPositionSchema()),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: BannerPositionModel) => {
		onSubmit(values);
	};

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues, reset]);

	useEffect(() => {
		if (detailModel) {
			setInitialValues((prev) => ({ ...prev, ...detailModel }));
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailModel]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='sm' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
						<TextBox
							name={'Tên vị trí'}
							error={errors.name?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, name: value }));
							}}
							value={initialValues.name}
							hasValid={true}
							label='Tên vị trí'
						/>
						<TextBox
							name={'Mã vị trí'}
							error={errors.code?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, code: value }));
							}}
							value={initialValues.code}
							hasValid={true}
							label='Mã vị trí'
						/>
						<NumberBox
							name={'Rộng'}
							error={errors.width?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, width: value }));
							}}
							value={initialValues.width}
							hasValid={true}
							label='Chiều rộng'
						/>
						<NumberBox
							name={'Cao'}
							error={errors.height?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, height: value }));
							}}
							value={initialValues.height}
							hasValid={true}
							label='Chiều cao'
						/>
						<FormGroup name='status_box' label='Trạng thái'>
							<SwitchBox
								name='inActive'
								label='Áp dụng'
								error={errors.inActive?.message}
								onValueChanged={(value) => {
									setInitialValues((prev) => ({ ...prev, inActive: value }));
								}}
								value={initialValues.inActive}
								sizeClass='sm'
							/>
						</FormGroup>
					</BaseForm>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave
						type='button'
						isLoading={isSaving}
						isDisabled={isSaving}
						onClick={handleSubmit(onSubmitHandler)}
						text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default BannerPositionDetail;
