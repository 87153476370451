import { all, call, put, fork, takeEvery } from "redux-saga/effects";
import { actions } from "../rootActions";
import { CollectionGroupAction_DELETE_Request, CollectionGroupAction_GETALL_Request, CollectionGroupAction_GETPAGINATION_Request, CollectionGroupAction_SAVE_Request, eCollectionGroupActionTypeIds } from "./ICollectionGroupActionsTypes";
import { BaseResponse } from "../../base/BaseResponse";
import { rootApi } from "../../api/rootApi";
import { CollectionGroupModel } from "../../models/collections/CollectionGroupModel";
import { CollectionGroupResponse } from "../../models/collections/CollectionGroupResponse";

const _sagaApi = rootApi.collectionGroup;

function* onLoadAllCollectionGroup(action: CollectionGroupAction_GETALL_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetColectionGroupByTypeAsync],action.payload);
        if (res.isSuccess && res.result) {
            const listCollection = res.result as CollectionGroupResponse[];
            yield put(actions.collectionGroup.getAllSuccess(listCollection));
        }
        else {
            yield put(actions.collectionGroup.getAllFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.collectionGroup.getAllFailure(error || 'Có lỗi'));
    }
}

function* onLoadPaginationCollectionGroup(action: CollectionGroupAction_GETPAGINATION_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetPaginationAsync], action.payload);
        if (res.isSuccess && res.result) {
            const listCollection = res.result as CollectionGroupModel[];
            yield put(actions.collectionGroup.getPaginationSuccess({ listDatas: listCollection, pagination: res.pagination }));
        }
        else {
            yield put(actions.collectionGroup.getPaginationFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.collectionGroup.getPaginationFailure(error || 'Có lỗi'));
    }
}

function* onSaveCollectionGroup(action: CollectionGroupAction_SAVE_Request) {
    try {
        if (action.payload.id > 0) {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.collectionGroup.saveSuccess(res.message));
                yield put(actions.collectionGroup.needReload());
            }
            else {
                yield put(actions.collectionGroup.saveFailure(res.message));
            }
        } else {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.collectionGroup.saveSuccess(res.message));
                yield put(actions.collectionGroup.needReload());
            }
            else {
                yield put(actions.collectionGroup.saveFailure(res.message));
            }
        }
    } catch (error: any) {
        yield put(actions.collectionGroup.saveFailure(error || 'Có lỗi'));
    }
}

function* onDeleteCollectionGroup(action: CollectionGroupAction_DELETE_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
        if (res.isSuccess) {
            yield put(actions.collectionGroup.deleteSuccess(res.message));
            yield put(actions.collectionGroup.needReload());
        }
        else {
            yield put(actions.collectionGroup.deleteFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.collectionGroup.deleteFailure(error || 'Có lỗi'));
    }
}

function* watchOnLoadAllCollectionGroup() {
    yield takeEvery(eCollectionGroupActionTypeIds.GET_ALL_REQUEST, onLoadAllCollectionGroup);
}

function* watchOnLoadPaginationCollectionGroup() {
    yield takeEvery(eCollectionGroupActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationCollectionGroup);
}


function* watchOnSaveCollectionGroup() {
    yield takeEvery(eCollectionGroupActionTypeIds.SAVE_REQUEST, onSaveCollectionGroup);
}

function* watchOnDeleteCollectionGroup() {
    yield takeEvery(eCollectionGroupActionTypeIds.DELETE_REQUEST, onDeleteCollectionGroup);
}

function* CollectionGroupSaga() {
    yield all([fork(watchOnLoadAllCollectionGroup)]);
    yield all([fork(watchOnLoadPaginationCollectionGroup)]);
    yield all([fork(watchOnSaveCollectionGroup)]);
    yield all([fork(watchOnDeleteCollectionGroup)]);
}

export default CollectionGroupSaga;