import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
	BaseForm,
	ButtonCancel,
	ButtonSave,
	FormGroup,
	ModalBody,
	ModalDraggable,
	ModalFooter,
	ModalHeader,
	NumberBox,
	SwitchBox,
	TextBox,
} from '../../components/base';

import { slugHelper } from '../../utils/slugHelper';
import { dfProductTypeModel, ProductTypeModel, validProductTypeSchema } from '../../context/models/product-type/ProductTypeModel';
import { ImageBox } from '../../components/base/ImageBox';

type IProductTypeDetailModalProps = {
	isShow: boolean;
	title: string;
	detailModel?: ProductTypeModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: ProductTypeModel) => void;
};

const ProductTypeDetail = (props: IProductTypeDetailModalProps) => {
	const { isShow, title, detailModel, onSubmit, onClose, isSaving } = props;
	const isAddMode = !detailModel;
	const [initialValues, setInitialValues] = useState<ProductTypeModel>(dfProductTypeModel);

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors, isSubmitting },
	} = useForm<ProductTypeModel>({
		resolver: yupResolver(validProductTypeSchema()),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: ProductTypeModel) => {
		onSubmit(values);
	};

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues, reset]);

	useEffect(() => {
		if (detailModel) {
			setInitialValues((prev) => ({ ...prev, ...detailModel }));
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailModel]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='sm' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
						<TextBox
							name={'name'}
							error={errors.name?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, name: value }));
							}}
							value={initialValues.name}
							hasValid={true}
							label='Tên chuyên mục'
						/>
						<TextBox
							name={'url'}
							error={errors.url?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, url: value }));
							}}
							value={initialValues.url}
							hasValid={true}
							label='Đường dẫn'
						/>
						<ImageBox
							name='background'
							isDisabled={initialValues.name.length <= 0}
							file_name={initialValues.name}
							value={initialValues.background}
							onDataChanged={(background) => {
								setInitialValues((prev) => ({
									...prev,
									background: background?.path || '',
								}));
							}}
							hasValid={true}
							label='Ảnh nền'
						/>
						<ImageBox
							name='icon'
							isDisabled={initialValues.name.length <= 0}
							file_name={initialValues.name}
							value={initialValues.icon}
							onDataChanged={(icon) => {
								setInitialValues((prev) => ({
									...prev,
									icon: icon?.path || '',
								}));
							}}
							hasValid={true}
							label='Icon'
						/>
						<NumberBox
							name={'stt'}
							error={errors.stt?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, stt: value }));
							}}
							value={initialValues.stt}
							hasValid={true}
							label='Số thứ tự'
						/>
					</BaseForm>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave
						type='button'
						isLoading={isSaving}
						isDisabled={isSaving}
						onClick={handleSubmit(onSubmitHandler)}
						text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default ProductTypeDetail;
