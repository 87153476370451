import { BaseShowDetail } from '../../base/BaseShowDetail';
import { IActionTypeBase } from '../../base/IBaseActionTypes';
import { BannerConfigModel } from '../../models/banners/BannerConfigModel';

export enum eBannerConfigActionTypeIds {
	GET_ALL_REQUEST = 'BannerConfig_GET_ALL_REQUEST',
	GET_ALL_SUCCESS = 'BannerConfig_GET_ALL_SUCCESS',
	GET_ALL_FAILURE = 'BannerConfig_GET_ALL_FAILURE',

	GET_PAGINATION_REQUEST = 'BannerConfig_GET_PAGINATION_REQUEST',
	GET_PAGINATION_SUCCESS = 'BannerConfig_GET_PAGINATION_SUCCESS',
	GET_PAGINATION_FAILURE = 'BannerConfig_GET_PAGINATION_FAILURE',

	SAVE_REQUEST = "BannerConfig_SAVE_REQUEST",
    SAVE_SUCCESS = "BannerConfig_SAVE_SUCCESS",
    SAVE_FAILURE = "BannerConfig_SAVE_FAILURE",

	NEED_RELOAD = 'BannerConfig_NEED_RELOAD',

	CHANGE_SELECTED_IDS = 'BannerConfig_CHANGE_SELECTED_IDS',
	SHOW_DETAIL = 'BannerConfig_SHOW_DETAIL',
	SHOW_CONFIRM = 'BannerConfig_SHOW_CONFIRM',
}

export interface BannerConfigAction_GETALL_Request extends IActionTypeBase<eBannerConfigActionTypeIds.GET_ALL_REQUEST, number> {}
export interface BannerConfigAction_GETALL_Success
	extends IActionTypeBase<eBannerConfigActionTypeIds.GET_ALL_SUCCESS, BannerConfigModel[]> {}
export interface BannerConfigAction_GETALL_Failure extends IActionTypeBase<eBannerConfigActionTypeIds.GET_ALL_FAILURE, Error | string> {}

export interface BannerConfigAction_SAVE_Request extends IActionTypeBase<eBannerConfigActionTypeIds.SAVE_REQUEST, BannerConfigModel> { }
export interface BannerConfigAction_SAVE_Success extends IActionTypeBase<eBannerConfigActionTypeIds.SAVE_SUCCESS, string> { }
export interface BannerConfigAction_SAVE_Failure extends IActionTypeBase<eBannerConfigActionTypeIds.SAVE_FAILURE, Error | string> { }

export interface BannerConfigAction_RELOAD extends IActionTypeBase<eBannerConfigActionTypeIds.NEED_RELOAD, undefined> {}

export interface BannerConfigAction_SHOW_DETAIL
	extends IActionTypeBase<eBannerConfigActionTypeIds.SHOW_DETAIL, BaseShowDetail<BannerConfigModel>> {}
export interface BannerConfigAction_CHANGE_SELECTED_IDS extends IActionTypeBase<eBannerConfigActionTypeIds.CHANGE_SELECTED_IDS, number[]> {}
export interface BannerConfigAction_SHOW_CONFIRM extends IActionTypeBase<eBannerConfigActionTypeIds.SHOW_CONFIRM, boolean> {}

export type BannerConfigActionTypes =
	| BannerConfigAction_GETALL_Request
	| BannerConfigAction_GETALL_Success
	| BannerConfigAction_GETALL_Failure
	| BannerConfigAction_SAVE_Request
	| BannerConfigAction_SAVE_Success
	| BannerConfigAction_SAVE_Failure
	| BannerConfigAction_RELOAD
	| BannerConfigAction_SHOW_DETAIL
	| BannerConfigAction_CHANGE_SELECTED_IDS
	| BannerConfigAction_SHOW_CONFIRM;
