import { CollectionItemActionTypes, eCollectionItemActionTypeIds } from './ICollectionItemActionsTypes';
import { BaseGetActionStatus, eBaseActionStatus } from '../../base/eBaseActionStatus';
import { NotifyHelper } from '../../../utils/NotifyHelper';
import { ICollectionItemState } from './ICollectionItemState';
import { CollectionItemModel } from '../../models/collections/CollectionItemModel';
import { BasePaginationRespone, dfBasePagination } from '../../base/BasePaginationRespone';
import { dfBaseShowDetail } from '../../base/BaseShowDetail';

const initPaginationResponse: BasePaginationRespone<CollectionItemModel> = { listDatas: [], pagination: dfBasePagination };
const initialState: ICollectionItemState = {
	status: eBaseActionStatus.idle,
	allCollectionsItem: [],
	selectedIds: [],
	showConfirm: false,
	paginationResponse: initPaginationResponse,
	showDetail: dfBaseShowDetail,
};
const CollectionItemReducer = (state: ICollectionItemState = initialState, action: CollectionItemActionTypes): ICollectionItemState => {
	switch (action.type) {
		case eCollectionItemActionTypeIds.GET_ALL_REQUEST:
		case eCollectionItemActionTypeIds.GET_PAGINATION_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eCollectionItemActionTypeIds.GET_ALL_SUCCESS:
			return {
				...state,
				allCollectionsItem: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eCollectionItemActionTypeIds.GET_PAGINATION_SUCCESS:
			return {
				...state,
				paginationResponse: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eCollectionItemActionTypeIds.GET_ALL_FAILURE:
			return {
				...state,
				allCollectionsItem: [],
				status: BaseGetActionStatus(action),
			};
		case eCollectionItemActionTypeIds.GET_PAGINATION_FAILURE:
			return {
				...state,
				paginationResponse: initPaginationResponse,
				status: BaseGetActionStatus(action),
			};
		case eCollectionItemActionTypeIds.CHANGE_SELECTED_IDS:
			return {
				...state,
				selectedIds: action.payload,
			};
		case eCollectionItemActionTypeIds.SHOW_DETAIL:
			return {
				...state,
				showDetail: action.payload,
			};
		case eCollectionItemActionTypeIds.SHOW_CONFIRM:
			return {
				...state,
				showConfirm: action.payload,
			};
		case eCollectionItemActionTypeIds.SAVE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eCollectionItemActionTypeIds.DELETE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eCollectionItemActionTypeIds.SAVE_SUCCESS:
			NotifyHelper.Success(action.payload || 'Thêm chuyên mục thành công.');
			return {
				...state,
				showDetail: dfBaseShowDetail,
				status: BaseGetActionStatus(action),
			};
		case eCollectionItemActionTypeIds.DELETE_SUCCESS:
			NotifyHelper.Success(action.payload.toString() || 'Xóa chuyên mục thành công');
			return {
				...state,
				selectedIds: [],
				showConfirm: false,
				status: BaseGetActionStatus(action),
			};
		case eCollectionItemActionTypeIds.SAVE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				showDetail: dfBaseShowDetail,
				status: BaseGetActionStatus(action),
			};
		case eCollectionItemActionTypeIds.DELETE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				selectedIds: [],
				showConfirm: false,
				status: BaseGetActionStatus(action),
			};
		case eCollectionItemActionTypeIds.NEED_RELOAD:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		default:
			return state;
	}
};
export default CollectionItemReducer;
