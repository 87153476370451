import { BasePaginationRespone } from '../../base/BasePaginationRespone';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { IActionTypeBase } from '../../base/IBaseActionTypes';
import { BannerModel } from '../../models/banners/BannerModel';

export enum eBannerActionTypeIds {
	GET_ALL_REQUEST = 'Banner_GET_ALL_REQUEST',
	GET_ALL_SUCCESS = 'Banner_GET_ALL_SUCCESS',
	GET_ALL_FAILURE = 'Banner_GET_ALL_FAILURE',

	GET_PAGINATION_REQUEST = 'Banner_GET_PAGINATION_REQUEST',
	GET_PAGINATION_SUCCESS = 'Banner_GET_PAGINATION_SUCCESS',
	GET_PAGINATION_FAILURE = 'Banner_GET_PAGINATION_FAILURE',

	SAVE_REQUEST = 'Banner_SAVE_REQUEST',
	SAVE_SUCCESS = 'Banner_SAVE_SUCCESS',
	SAVE_FAILURE = 'Banner_SAVE_FAILURE',

	GET_DETAIL_REQUEST = 'Banner_GET_DETAIL_REQUEST',
	GET_DETAIL_SUCCESS = 'Banner_GET_DETAIL_SUCCESS',
	GET_DETAIL_FAILURE = 'Banner_GET_DETAIL_FAILURE',

	DELETE_REQUEST = 'Banner_DELETE_REQUEST',
	DELETE_SUCCESS = 'Banner_DELETE_SUCCESS',
	DELETE_FAILURE = 'Banner_DELETE_FAILURE',

	NEED_RELOAD = 'Banner_NEED_RELOAD',

	CHANGE_SELECTED_IDS = 'Banner_CHANGE_SELECTED_IDS',
	SHOW_DETAIL = 'Banner_SHOW_DETAIL',
	SHOW_CONFIRM = 'Banner_SHOW_CONFIRM',
}

export interface BannerAction_GETALL_Request extends IActionTypeBase<eBannerActionTypeIds.GET_ALL_REQUEST, undefined> {}
export interface BannerAction_GETALL_Success extends IActionTypeBase<eBannerActionTypeIds.GET_ALL_SUCCESS, BannerModel[]> {}
export interface BannerAction_GETALL_Failure extends IActionTypeBase<eBannerActionTypeIds.GET_ALL_FAILURE, Error | string> {}

export interface BannerAction_GETPAGINATION_Request
	extends IActionTypeBase<eBannerActionTypeIds.GET_PAGINATION_REQUEST, BaseSelectRequest> {}
export interface BannerAction_GETPAGINATION_Success
	extends IActionTypeBase<eBannerActionTypeIds.GET_PAGINATION_SUCCESS, BasePaginationRespone<BannerModel>> {}
export interface BannerAction_GETPAGINATION_Failure extends IActionTypeBase<eBannerActionTypeIds.GET_PAGINATION_FAILURE, Error | string> {}

export interface BannerAction_SAVE_Request extends IActionTypeBase<eBannerActionTypeIds.SAVE_REQUEST, BannerModel> {}
export interface BannerAction_SAVE_Success extends IActionTypeBase<eBannerActionTypeIds.SAVE_SUCCESS, string> {}
export interface BannerAction_SAVE_Failure extends IActionTypeBase<eBannerActionTypeIds.SAVE_FAILURE, Error | string> {}

export interface BannerAction_GET_DETAIL_Request extends IActionTypeBase<eBannerActionTypeIds.GET_DETAIL_REQUEST, number> {}
export interface BannerAction_GET_DETAIL_Success
	extends IActionTypeBase<eBannerActionTypeIds.GET_DETAIL_SUCCESS, BannerModel | undefined> {}
export interface BannerAction_GET_DETAIL_Failure extends IActionTypeBase<eBannerActionTypeIds.GET_DETAIL_FAILURE, Error | string> {}

export interface BannerAction_DELETE_Request extends IActionTypeBase<eBannerActionTypeIds.DELETE_REQUEST, number[]> {}
export interface BannerAction_DELETE_Success extends IActionTypeBase<eBannerActionTypeIds.DELETE_SUCCESS, string> {}
export interface BannerAction_DELETE_Failure extends IActionTypeBase<eBannerActionTypeIds.DELETE_FAILURE, Error | string> {}

export interface BannerAction_RELOAD extends IActionTypeBase<eBannerActionTypeIds.NEED_RELOAD, undefined> {}

export interface BannerAction_SHOW_DETAIL extends IActionTypeBase<eBannerActionTypeIds.SHOW_DETAIL, BaseShowDetail<BannerModel>> {}
export interface BannerAction_CHANGE_SELECTED_IDS extends IActionTypeBase<eBannerActionTypeIds.CHANGE_SELECTED_IDS, number[]> {}
export interface BannerAction_SHOW_CONFIRM extends IActionTypeBase<eBannerActionTypeIds.SHOW_CONFIRM, boolean> {}

export type BannerActionTypes =
	| BannerAction_GETALL_Request
	| BannerAction_GETALL_Success
	| BannerAction_GETALL_Failure
	| BannerAction_GETPAGINATION_Request
	| BannerAction_GETPAGINATION_Success
	| BannerAction_GETPAGINATION_Failure
	| BannerAction_SAVE_Request
	| BannerAction_SAVE_Success
	| BannerAction_SAVE_Failure
	| BannerAction_GET_DETAIL_Request
	| BannerAction_GET_DETAIL_Success
	| BannerAction_GET_DETAIL_Failure
	| BannerAction_DELETE_Request
	| BannerAction_DELETE_Success
	| BannerAction_DELETE_Failure
	| BannerAction_RELOAD
	| BannerAction_SHOW_DETAIL
	| BannerAction_CHANGE_SELECTED_IDS
	| BannerAction_SHOW_CONFIRM;
