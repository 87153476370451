import { all, call, put, fork, takeEvery } from 'redux-saga/effects';
import { actions } from '../rootActions';
import {
	BannerConfigAction_GETALL_Request,
	BannerConfigAction_SAVE_Request,
	eBannerConfigActionTypeIds,
} from './IBannerConfigActionsTypes';
import { BaseResponse } from '../../base/BaseResponse';
import { rootApi } from '../../api/rootApi';
import { BannerConfigModel } from '../../models/banners/BannerConfigModel';

const _sagaApi = rootApi.bannerConfig;

function* onLoadAllBannerConfig(action: BannerConfigAction_GETALL_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetAllAsync]);
		if (res.isSuccess && res.result) {
			const listBannerConfig = res.result as BannerConfigModel[];
			yield put(actions.bannerConfig.getAllSuccess(listBannerConfig));
		} else {
			yield put(actions.bannerConfig.getAllFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.bannerConfig.getAllFailure(error || 'Có lỗi'));
	}
}

function* onSaveBannerConfig(action: BannerConfigAction_SAVE_Request) {
    try {
        if (action.payload.id > 0) {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.bannerConfig.saveSuccess(res.message));
                yield put(actions.bannerConfig.needReload());
            }
            else {
                yield put(actions.bannerConfig.saveFailure(res.message));
            }
        } else {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.bannerConfig.saveSuccess(res.message));
                yield put(actions.bannerConfig.needReload());
            }
            else {
                yield put(actions.bannerConfig.saveFailure(res.message));
            }
        }
    } catch (error: any) {
        yield put(actions.bannerConfig.saveFailure(error || 'Có lỗi'));
    }
}

function* watchOnLoadAllBannerConfig() {
	yield takeEvery(eBannerConfigActionTypeIds.GET_ALL_REQUEST, onLoadAllBannerConfig);
}
function* watchOnSaveBannerConfig() {
    yield takeEvery(eBannerConfigActionTypeIds.SAVE_REQUEST, onSaveBannerConfig);
}

function* BannerConfigSaga() {
	yield all([fork(watchOnLoadAllBannerConfig)]);
	yield all([fork(watchOnSaveBannerConfig)]);
}

export default BannerConfigSaga;
