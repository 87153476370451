import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, ButtonLink, LoadingTable } from '../../components/base';
import { ConfirmModal } from '../../components/common/ConfirmModal';
import DxTable, { DxLookup, DxTableColumn } from '../../components/common/DxTable';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../components/container';
import { BaseShowDetail } from '../../context/base/BaseShowDetail';
import { eBaseActionStatus } from '../../context/base/eBaseActionStatus';
import { actions } from '../../context/stores/rootActions';
import { AppState } from '../../context/stores/rootReducers';
import { createUUID } from '../../utils/createUUID';
import { CollectionItemModel } from '../../context/models/collections/CollectionItemModel';
import { CollectionItemActionTypes } from '../../context/stores/collections-item/ICollectionItemActionsTypes';
import CollectionItemDetail from '../collections-item/CollectionItemDetail';
// import { useLookupCollection } from '../../components/shared/hoc-collection/useLookupCollection';
import { CollectionItemResponse } from '../../context/models/collections/CollectionItemResponse';
import ComboBoxCollection from '../../components/shared/hoc-collection/ComboBoxCollection';
import { useLookupCollection } from '../../components/shared/hoc-collection/useLookupCollection';
import { useLookupKeywordSeo } from '../../components/shared/hoc-keyword-seo/useLookupKeywordSeo';
// import ArticleCategoryDetail from './ArticleCategoryDetail';
type ICollectionItemPageProps = {
	titlePage: string;
	current_collection_id: number;
	status: eBaseActionStatus;
	allCollectionsItem: CollectionItemResponse[];
	selectedIds: number[];
	showDetail: BaseShowDetail<CollectionItemModel>;
	showConfirm: boolean;
	handleReloadAllData: (collect_id: number) => void;
	handleSaveData: (data: CollectionItemModel) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	onShowDetail: (data: BaseShowDetail<CollectionItemModel>) => void;
	onShowConfirm: (isShow: boolean) => void;
};

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `từ khóa thuộc vị trí`,
		status: state.collectionItem.status,
		current_collection_id: parseInt(localStorage.getItem('current_collection_id') || '1'),
		allCollectionsItem: state.collectionItem.allCollectionsItem,
		selectedIds: state.collectionItem.selectedIds,
		showDetail: state.collectionItem.showDetail,
		showConfirm: state.collectionItem.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<CollectionItemActionTypes>) => ({
	handleReloadAllData: (collectID: number) => {
		dispatch(actions.collectionItem.getAllRequest({ collectID }));
	},
	handleSaveData: (data: CollectionItemModel) => {
		dispatch(actions.collectionItem.saveRequest(data));
	},
	handleDeleteData: (ids: number[]) => {
		dispatch(actions.collectionItem.deleteRequest(ids));
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.collectionItem.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetail<CollectionItemModel>) => {
		dispatch(actions.collectionItem.showDetail(data));
	},
	onShowConfirm: (isShow: boolean) => {
		dispatch(actions.collectionItem.showConfirm(isShow));
	},
});

const CollectionItemPage = (props: ICollectionItemPageProps) => {
	const {
		titlePage,
		status,
		selectedIds,
		showConfirm,
		showDetail,
		allCollectionsItem,
		handleReloadAllData,
		handleDeleteData,
		onChangeSelectedIds,
		handleSaveData,
		onShowDetail,
		onShowConfirm,
		current_collection_id,
	} = props;
	allCollectionsItem.sort((a, b) => a.stt - b.stt);

	const [collectID, setCollectID] = useState<number>(current_collection_id);
	const { collection_all } = useLookupCollection();
	const { keyword_seo_all } = useLookupKeywordSeo();

	// const { collection_all } = useLookupCollection();
	var handleReloadData = useCallback(() => {
		handleReloadAllData(collectID);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collectID]);

	useEffect(() => {
		handleReloadData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collectID]);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const columns = useMemo((): any => {
		let result: any = [
			<DxTableColumn key={createUUID()} dataField='stt' caption={`Số thứ tự`} alignment='center' width={100} fixed></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='name' caption={`Từ khóa`} minWidth={150} fixed>
				{/* <DxLookup dataSource={keyword_seo_all} valueExpr={'name'} displayExpr={'name'} /> */}
			</DxTableColumn>,
			// <DxTableColumn key={createUUID()} dataField='collectID' width={150} caption={`Vị trí`}>
			// 	<DxLookup dataSource={collection_all} valueExpr={'id'} displayExpr={'name'} />
			// </DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='link' minWidth={150} caption={`Đường dẫn`}></DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={50}
				caption={'Sửa'}
				alignment='center'
				allowExporting={true}
				fixed
				cellRender={(cell: any) => {
					return (
						<ButtonLink onClick={() => onShowDetail({ isShow: true, detailData: cell.data })} title='Sửa' icon='ni ni-edit' theme='link' />
					);
				}}
			/>
		);
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collection_all, keyword_seo_all]);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							{selectedIds.length > 0 && (
								<li>
									<Button
										text={`Xóa (${selectedIds.length})`}
										icon='icon ni ni-trash'
										className='d-md-inline-flex'
										theme='danger'
										onClick={() => onShowConfirm(true)}
									/>
								</li>
							)}
							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => onShowDetail({ isShow: true })}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						<li className='col col-lg-3'>
							<ComboBoxCollection
								name={'collectID'}
								onValueChanged={(value) => {
									setCollectID(value ? parseInt(value) : 0);
								}}
								value={collectID}
								placeholder='Nhập bộ sưu tập...'
								sizeClass='xs'
							/>
						</li>
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={allCollectionsItem}
								keyExpr='id'
								columns={columns}
								hasFixed={true}
								isLoading={status !== eBaseActionStatus.complete}
								defaultPageSize={50}
								filters={{
									refreshDataGrid: handleReloadData,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
			</PageContainer>
			{showDetail && showDetail.isShow && (
				<>
					<CollectionItemDetail
						isSaving={status === eBaseActionStatus.saving}
						isShow={showDetail.isShow}
						detailModel={showDetail.detailData}
						onClose={() => onShowDetail({ isShow: false })}
						onSubmit={(data) => handleSaveData(data)}
						title={`${showDetail.detailData ? `Cập nhật` : `Thêm mới`} ${titlePage}`}
					/>
				</>
			)}
			{showConfirm && (
				<>
					<ConfirmModal
						show={showConfirm}
						innerText={`Bạn chắc chắn muốn xóa ${selectedIds.length} ${titlePage.toLowerCase()} đã chọn ?`}
						type='danger'
						onClose={() => onShowConfirm(false)}
						onConfirmed={() => handleDeleteData(selectedIds)}
						isLoading={status === eBaseActionStatus.loading || status === eBaseActionStatus.saving}
						btnConfirmText='Xóa'
						btnCloseText='Không xóa'
					/>
				</>
			)}
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionItemPage);
