import { all, call, put, fork, takeEvery } from "redux-saga/effects";
import { actions } from "../rootActions";
import { ProductImageAction_DELETE_Request, ProductImageAction_GETALL_Request, ProductImageAction_SAVE_Request, eProductImageActionTypeIds } from "./IProductImageActionsTypes";
import { BaseResponse } from "../../base/BaseResponse";
import { rootApi } from "../../api/rootApi";
import { ProductImageModel } from "../../models/products-image/ProductImageModel";

const _sagaApi = rootApi.productImage;

function* onLoadAllProductImage(action: ProductImageAction_GETALL_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetAllByProductAsync], action.payload);
        if (res.isSuccess && res.result) {
            const listProductImage = res.result as ProductImageModel[];
            yield put(actions.productImage.getAllSuccess(listProductImage));
        }
        else {
            yield put(actions.productImage.getAllFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.productImage.getAllFailure(error || 'Có lỗi'));
    }
}

function* onSaveProductImage(action: ProductImageAction_SAVE_Request) {
    try {
        if (action.payload.id > 0) {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.productImage.saveSuccess(res.message));
                yield put(actions.productImage.needReload());
            }
            else {
                yield put(actions.productImage.saveFailure(res.message));
            }
        } else {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.productImage.saveSuccess(res.message));
                yield put(actions.productImage.needReload());
            }
            else {
                yield put(actions.productImage.saveFailure(res.message));
            }
        }
    } catch (error: any) {
        yield put(actions.productImage.saveFailure(error || 'Có lỗi'));
    }
}

function* onDeleteProductImage(action: ProductImageAction_DELETE_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
        if (res.isSuccess) {
            yield put(actions.productImage.deleteSuccess(res.message));
            yield put(actions.productImage.needReload());
        }
        else {
            yield put(actions.productImage.deleteFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.productImage.deleteFailure(error || 'Có lỗi'));
    }
}

function* watchOnLoadAllProductImage() {
    yield takeEvery(eProductImageActionTypeIds.GET_ALL_REQUEST, onLoadAllProductImage);
}

function* watchOnSaveProductImage() {
    yield takeEvery(eProductImageActionTypeIds.SAVE_REQUEST, onSaveProductImage);
}

function* watchOnDeleteProductImage() {
    yield takeEvery(eProductImageActionTypeIds.DELETE_REQUEST, onDeleteProductImage);
}

function* productImageSaga() {
    yield all([fork(watchOnLoadAllProductImage)]);
    yield all([fork(watchOnSaveProductImage)]);
    yield all([fork(watchOnDeleteProductImage)]);
}

export default productImageSaga;