import { BasePaginationRespone } from '../../base/BasePaginationRespone';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { BaseAction } from '../../base/IBaseActionRespone';
import { KeywordModel } from '../../models/keyword/KeywordModel';
import { KeywordRequest } from '../../models/keyword/KeywordRequest';
import { KeywordResponse } from '../../models/keyword/KeywordResponse';
import {
	KeywordAction_CHANGE_SELECTED_IDS,
	KeywordAction_DELETE_Failure,
	KeywordAction_DELETE_Request,
	KeywordAction_DELETE_Success,
	KeywordAction_GETALL_Failure,
	KeywordAction_GETALL_Request,
	KeywordAction_GETALL_Success,
	KeywordAction_GETPAGINATION_Failure,
	KeywordAction_GETPAGINATION_Request,
	KeywordAction_GETPAGINATION_Success,
	KeywordAction_RELOAD,
	KeywordAction_SAVE_Failure,
	KeywordAction_SAVE_Request,
	KeywordAction_SAVE_Success,
	KeywordAction_SHOW_CONFIRM,
	KeywordAction_SHOW_DETAIL,
	eKeywordActionTypeIds,
} from './IKeywordActionsTypes';

export const keywordActions = {
	//ALL
	getAllRequest: (data : KeywordRequest): KeywordAction_GETALL_Request => BaseAction(eKeywordActionTypeIds.GET_ALL_REQUEST, data),
	getAllSuccess: (data: KeywordResponse[]): KeywordAction_GETALL_Success => BaseAction(eKeywordActionTypeIds.GET_ALL_SUCCESS, data),
	getAllFailure: (error: Error | string): KeywordAction_GETALL_Failure => BaseAction(eKeywordActionTypeIds.GET_ALL_FAILURE, error),
	//LIST
	getPaginationRequest: (request: BaseSelectRequest): KeywordAction_GETPAGINATION_Request =>
		BaseAction(eKeywordActionTypeIds.GET_PAGINATION_REQUEST, request),
	getPaginationSuccess: (data: BasePaginationRespone<KeywordModel>): KeywordAction_GETPAGINATION_Success =>
		BaseAction(eKeywordActionTypeIds.GET_PAGINATION_SUCCESS, data),
	getPaginationFailure: (error: Error | string): KeywordAction_GETPAGINATION_Failure =>
		BaseAction(eKeywordActionTypeIds.GET_PAGINATION_FAILURE, error),
	//SAVE
	saveRequest: (entity: KeywordModel): KeywordAction_SAVE_Request => BaseAction(eKeywordActionTypeIds.SAVE_REQUEST, entity),
	saveSuccess: (message: string): KeywordAction_SAVE_Success => BaseAction(eKeywordActionTypeIds.SAVE_SUCCESS, message),
	saveFailure: (error: Error | string): KeywordAction_SAVE_Failure => BaseAction(eKeywordActionTypeIds.SAVE_FAILURE, error),
	//DELETE
	deleteRequest: (ids: number[]): KeywordAction_DELETE_Request => BaseAction(eKeywordActionTypeIds.DELETE_REQUEST, ids),
	deleteSuccess: (message: string): KeywordAction_DELETE_Success => BaseAction(eKeywordActionTypeIds.DELETE_SUCCESS, message),
	deleteFailure: (error: Error | string): KeywordAction_DELETE_Failure => BaseAction(eKeywordActionTypeIds.DELETE_FAILURE, error),
	//RELOAD
	needReload: (): KeywordAction_RELOAD => BaseAction(eKeywordActionTypeIds.NEED_RELOAD, undefined),
	//ACTIONs
	changeSelectedIds: (ids: number[]): KeywordAction_CHANGE_SELECTED_IDS => BaseAction(eKeywordActionTypeIds.CHANGE_SELECTED_IDS, ids),
	showDetail: (detail: BaseShowDetail<KeywordModel>): KeywordAction_SHOW_DETAIL => BaseAction(eKeywordActionTypeIds.SHOW_DETAIL, detail),
	showConfirm: (show: boolean): KeywordAction_SHOW_CONFIRM => BaseAction(eKeywordActionTypeIds.SHOW_CONFIRM, show),
};
