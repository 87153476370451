import { BaseShowDetail } from "../../base/BaseShowDetail";
import { IActionTypeBase } from "../../base/IBaseActionTypes";
import { ProductImageModel } from "../../models/products-image/ProductImageModel";


export enum eProductImageActionTypeIds {
    GET_ALL_REQUEST = "ProductImage_GET_ALL_REQUEST",
    GET_ALL_SUCCESS = "ProductImage_GET_ALL_SUCCESS",
    GET_ALL_FAILURE = "ProductImage_GET_ALL_FAILURE",

    GET_PAGINATION_REQUEST = "ProductImage_GET_PAGINATION_REQUEST",
    GET_PAGINATION_SUCCESS = "ProductImage_GET_PAGINATION_SUCCESS",
    GET_PAGINATION_FAILURE = "ProductImage_GET_PAGINATION_FAILURE",

    SAVE_REQUEST = "ProductImage_SAVE_REQUEST",
    SAVE_SUCCESS = "ProductImage_SAVE_SUCCESS",
    SAVE_FAILURE = "ProductImage_SAVE_FAILURE",

    DELETE_REQUEST = "ProductImage_DELETE_REQUEST",
    DELETE_SUCCESS = "ProductImage_DELETE_SUCCESS",
    DELETE_FAILURE = "ProductImage_DELETE_FAILURE",

    NEED_RELOAD = "ProductImage_NEED_RELOAD",

    CHANGE_SELECTED_IDS = "ProductImage_CHANGE_SELECTED_IDS",
    SHOW_DETAIL = "ProductImage_SHOW_DETAIL",
    SHOW_CONFIRM = "ProductImage_SHOW_CONFIRM",
}

export interface ProductImageAction_GETALL_Request extends IActionTypeBase<eProductImageActionTypeIds.GET_ALL_REQUEST, number> { }
export interface ProductImageAction_GETALL_Success extends IActionTypeBase<eProductImageActionTypeIds.GET_ALL_SUCCESS, ProductImageModel[]> { }
export interface ProductImageAction_GETALL_Failure extends IActionTypeBase<eProductImageActionTypeIds.GET_ALL_FAILURE, Error | string> { }

export interface ProductImageAction_SAVE_Request extends IActionTypeBase<eProductImageActionTypeIds.SAVE_REQUEST, ProductImageModel> { }
export interface ProductImageAction_SAVE_Success extends IActionTypeBase<eProductImageActionTypeIds.SAVE_SUCCESS, string> { }
export interface ProductImageAction_SAVE_Failure extends IActionTypeBase<eProductImageActionTypeIds.SAVE_FAILURE, Error | string> { }

export interface ProductImageAction_DELETE_Request extends IActionTypeBase<eProductImageActionTypeIds.DELETE_REQUEST, number[]> { }
export interface ProductImageAction_DELETE_Success extends IActionTypeBase<eProductImageActionTypeIds.DELETE_SUCCESS, string> { }
export interface ProductImageAction_DELETE_Failure extends IActionTypeBase<eProductImageActionTypeIds.DELETE_FAILURE, Error | string> { }

export interface ProductImageAction_RELOAD extends IActionTypeBase<eProductImageActionTypeIds.NEED_RELOAD, undefined> { }

export interface ProductImageAction_SHOW_DETAIL extends IActionTypeBase<eProductImageActionTypeIds.SHOW_DETAIL, BaseShowDetail<ProductImageModel>> { }
export interface ProductImageAction_CHANGE_SELECTED_IDS extends IActionTypeBase<eProductImageActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }
export interface ProductImageAction_SHOW_CONFIRM extends IActionTypeBase<eProductImageActionTypeIds.SHOW_CONFIRM, boolean> { }

export type ProductImageActionTypes = ProductImageAction_GETALL_Request | ProductImageAction_GETALL_Success | ProductImageAction_GETALL_Failure |
    ProductImageAction_SAVE_Request | ProductImageAction_SAVE_Success | ProductImageAction_SAVE_Failure |
    ProductImageAction_DELETE_Request | ProductImageAction_DELETE_Success | ProductImageAction_DELETE_Failure | ProductImageAction_RELOAD |
    ProductImageAction_SHOW_DETAIL | ProductImageAction_CHANGE_SELECTED_IDS | ProductImageAction_SHOW_CONFIRM 