import { BasePagination, BasePaginationRespone } from '../../base/BasePaginationRespone';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { BaseAction } from '../../base/IBaseActionRespone';
import { KeywordSeoModel } from '../../models/crm/KeywordSeoModel';
import {
    KeywordAction_GETALL_Failure,
    KeywordAction_GETALL_Request,
	KeywordAction_GETALL_Success,
	KeywordSeoAction_CHANGE_SELECTED_IDS,
	KeywordSeoAction_GETKEYWORD_Failure,
	KeywordSeoAction_GETKEYWORD_Request,
	KeywordSeoAction_GETKEYWORD_Success,
	KeywordSeoAction_RELOAD,
	KeywordSeoAction_SHOW_CONFIRM,
	KeywordSeoAction_SHOW_DETAIL,
	eKeywordSeoActionTypeIds,
} from './IKeywordSeoActionsTypes';

export const KeywordSeoActions = {
	//ALL
	getAllRequest: (): KeywordAction_GETALL_Request => BaseAction(eKeywordSeoActionTypeIds.GET_ALL_REQUEST, undefined),
	getAllSuccess: (data: KeywordSeoModel[]): KeywordAction_GETALL_Success =>
		BaseAction(eKeywordSeoActionTypeIds.GET_ALL_SUCCESS, data),
	getAllFailure: (error: Error | string): KeywordAction_GETALL_Failure =>
		BaseAction(eKeywordSeoActionTypeIds.GET_ALL_FAILURE, error),
	//KEYWORDSEO
	getKeywordRequest: (): KeywordSeoAction_GETKEYWORD_Request => BaseAction(eKeywordSeoActionTypeIds.GET_KEYWORD_REQUEST, undefined),
	getKeywordSuccess: (data: KeywordSeoModel[]): KeywordSeoAction_GETKEYWORD_Success =>
		BaseAction(eKeywordSeoActionTypeIds.GET_KEYWORD_SUCCESS, data),
	getKeywordFailure: (error: Error | string): KeywordSeoAction_GETKEYWORD_Failure =>
		BaseAction(eKeywordSeoActionTypeIds.GET_KEYWORD_FAILURE, error),
	//RELOAD
	needReload: (): KeywordSeoAction_RELOAD => BaseAction(eKeywordSeoActionTypeIds.NEED_RELOAD, undefined),
	//ACTIONs
	changeSelectedIds: (ids: number[]): KeywordSeoAction_CHANGE_SELECTED_IDS => BaseAction(eKeywordSeoActionTypeIds.CHANGE_SELECTED_IDS, ids),
	showDetail: (detail: BaseShowDetail<KeywordSeoModel>): KeywordSeoAction_SHOW_DETAIL =>
		BaseAction(eKeywordSeoActionTypeIds.SHOW_DETAIL, detail),
	showConfirm: (show: boolean): KeywordSeoAction_SHOW_CONFIRM => BaseAction(eKeywordSeoActionTypeIds.SHOW_CONFIRM, show),
};
