import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createUUID } from '../../utils/createUUID';
import { eBaseActionStatus } from '../../context/base/eBaseActionStatus';
import { BlockCard } from '../../components/container';
import DxTable, { DxLookup, DxTableColumn, DxToolbarItem } from '../../components/common/DxTable';
import { Button, ButtonLink, LoadingTable } from '../../components/base';
import { BannerConfigModel } from '../../context/models/banners/BannerConfigModel';
import { BaseShowDetail } from '../../context/base/BaseShowDetail';
import { actions } from '../../context/stores/rootActions';
import { AppState } from '../../context/stores/rootReducers';
import { useLookupBannerPosition } from '../../components/shared/hoc-banner-position/useLookupBannerPosition';
import { ProductCategoryModel } from '../../context/models/products-category/ProductCategoryModel';
import BannerConfigModal from './BannerConfigModal';

type IBannerConfigTableProps = {
	bannerId:number;
	positionId:number;
	listPageId:number
};

const BannerConfigTable = ({ bannerId, positionId, listPageId}: IBannerConfigTableProps) => {
	const dispatch = useDispatch();
	const { status, allBannerConfigs, showConfirm, selectedIds } = useSelector((state: AppState) => state.bannerConfig);
	const { showDetail } = useSelector((state: AppState) => state.productCategory);


	const dataSources = useMemo(() => {
		return allBannerConfigs.sort((a, b) => a.id - b.id);
	}, [allBannerConfigs]);

	const handleReloadDataWithFilter = useCallback(
		() => {
			dispatch(actions.bannerPosition.getAllRequest());
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const initBannerConfig = useMemo(() => {
		return {
			id: 0,
			bannerId: bannerId,
			positionId: positionId,
			listPageId: listPageId,
		} as unknown as BannerConfigModel;
	}, [bannerId, positionId, listPageId]);

	const handleSaveData = (data: BannerConfigModel) => {
		dispatch(actions.bannerConfig.saveRequest(data));
	};

	const { banner_position_all } = useLookupBannerPosition();

	const onChangeSelectedIds = (ids: number[]) => {
		dispatch(actions.bannerConfig.changeSelectedIds(ids));
	};
	const onShowDetail = (data: BaseShowDetail<ProductCategoryModel>) => {
		dispatch(actions.productCategory.showDetail(data));
	};

	useEffect(() => {
		handleReloadDataWithFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bannerId]);

	useEffect(() => {
		if (status == eBaseActionStatus.reload) handleReloadDataWithFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const columns = useMemo((): any => {
		let result: any = [
			<DxTableColumn key={createUUID()} dataField='positionId' caption={`Vị trí`} minWidth={150} fixed>
				<DxLookup dataSource={banner_position_all} valueExpr={'id'} displayExpr={'name'} />
			</DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={100}
				caption={'Trang hiển thị'}
				alignment='center'
				allowExporting={true}
				fixed
				cellRender={(cell: any) => {
					return (
						<>
							<ButtonLink
								onClick={() => onShowDetail({ isShow: true, detailData: cell.data })}
								title='Trang hiển thị'
								icon='ni ni-edit'
								theme='link'
							/>
						</>
					);
				}}
			/>
		);
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initBannerConfig,banner_position_all]);

	return (
		<>
			<div className='col-sm-12'>
				{bannerId > 0 && (
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={dataSources}
								keyExpr='id'
								columns={columns}
								hasFixed={true}
								isLoading={status !== eBaseActionStatus.complete}
								defaultPageSize={50}
								height={window.innerHeight - 300}
								wordWrapEnabled={true}
								filters={{
									refreshDataGrid: handleReloadDataWithFilter,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				)}
				{bannerId <= 0 && <div className='alert alert-warning'>Vui lòng lưu Banner để bổ sung vị trí Banner</div>}
			</div>
			{showDetail && showDetail.isShow && (
				<>
					<BannerConfigModal
						isSaving={status === eBaseActionStatus.saving}
						isShow={showDetail.isShow}
						onClose={() => onShowDetail({ isShow: false })}
						onSubmit={(data) => handleSaveData(data)}
						title={`${showDetail.detailData ? `Cập nhật` : `Thêm mới`} trang hiển thị`}
					/>
				</>
			)}
		</>
	);
};

export default BannerConfigTable;
