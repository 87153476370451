import { BasePaginationRespone } from "../../base/BasePaginationRespone";
import { BaseSelectRequest } from "../../base/BaseSelectRequest";
import { BaseShowDetail } from "../../base/BaseShowDetail";
import { BaseAction } from "../../base/IBaseActionRespone";
import { BannerPositionModel } from "../../models/banners/BannerPositionModel";
import { BannerPositionAction_CHANGE_SELECTED_IDS, BannerPositionAction_DELETE_Failure, BannerPositionAction_DELETE_Request, BannerPositionAction_DELETE_Success, BannerPositionAction_GETALL_Failure, BannerPositionAction_GETALL_Request, BannerPositionAction_GETALL_Success, BannerPositionAction_GETPAGINATION_Failure, BannerPositionAction_GETPAGINATION_Request, BannerPositionAction_GETPAGINATION_Success, BannerPositionAction_RELOAD, BannerPositionAction_SAVE_Failure, BannerPositionAction_SAVE_Request, BannerPositionAction_SAVE_Success, BannerPositionAction_SHOW_CONFIRM, BannerPositionAction_SHOW_DETAIL, eBannerPositionActionTypeIds } from "./IBannerPositionActionsTypes";

export const BannerPositionActions = {
    //ALL
    getAllRequest: (): BannerPositionAction_GETALL_Request => BaseAction(eBannerPositionActionTypeIds.GET_ALL_REQUEST, undefined),
    getAllSuccess: (data: BannerPositionModel[]): BannerPositionAction_GETALL_Success => BaseAction(eBannerPositionActionTypeIds.GET_ALL_SUCCESS, data),
    getAllFailure: (error: Error | string): BannerPositionAction_GETALL_Failure => BaseAction(eBannerPositionActionTypeIds.GET_ALL_FAILURE, error),
    //LIST
    getPaginationRequest: (request: BaseSelectRequest): BannerPositionAction_GETPAGINATION_Request => BaseAction(eBannerPositionActionTypeIds.GET_PAGINATION_REQUEST, request),
    getPaginationSuccess: (data: BasePaginationRespone<BannerPositionModel>): BannerPositionAction_GETPAGINATION_Success => BaseAction(eBannerPositionActionTypeIds.GET_PAGINATION_SUCCESS, data),
    getPaginationFailure: (error: Error | string): BannerPositionAction_GETPAGINATION_Failure => BaseAction(eBannerPositionActionTypeIds.GET_PAGINATION_FAILURE, error),
    //SAVE
    saveRequest: (entity: BannerPositionModel): BannerPositionAction_SAVE_Request => BaseAction(eBannerPositionActionTypeIds.SAVE_REQUEST, entity),
    saveSuccess: (message: string): BannerPositionAction_SAVE_Success => BaseAction(eBannerPositionActionTypeIds.SAVE_SUCCESS, message),
    saveFailure: (error: Error | string): BannerPositionAction_SAVE_Failure => BaseAction(eBannerPositionActionTypeIds.SAVE_FAILURE, error),
    //DELETE
    deleteRequest: (ids: number[]): BannerPositionAction_DELETE_Request => BaseAction(eBannerPositionActionTypeIds.DELETE_REQUEST, ids),
    deleteSuccess: (message: string): BannerPositionAction_DELETE_Success => BaseAction(eBannerPositionActionTypeIds.DELETE_SUCCESS, message),
    deleteFailure: (error: Error | string): BannerPositionAction_DELETE_Failure => BaseAction(eBannerPositionActionTypeIds.DELETE_FAILURE, error),
    //RELOAD
    needReload: (): BannerPositionAction_RELOAD => BaseAction(eBannerPositionActionTypeIds.NEED_RELOAD, undefined),
    //ACTIONs
    changeSelectedIds: (ids: number[]): BannerPositionAction_CHANGE_SELECTED_IDS => BaseAction(eBannerPositionActionTypeIds.CHANGE_SELECTED_IDS, ids),
    showDetail: (detail: BaseShowDetail<BannerPositionModel>): BannerPositionAction_SHOW_DETAIL => BaseAction(eBannerPositionActionTypeIds.SHOW_DETAIL, detail),
    showConfirm: (show: boolean): BannerPositionAction_SHOW_CONFIRM => BaseAction(eBannerPositionActionTypeIds.SHOW_CONFIRM, show)
}