import { ApiBase } from "../../base/_ApiBase";
import { ApiClient } from "../../base/_ApiClient";
import { ProductByCategoryRequest } from "../../models/product-type/ProductByCategoryRequest";
import { ProductTypeProductModel } from "../../models/product-type/ProductTypeProductModel";
import { ProductTypeProductRequest } from "../../models/product-type/ProductTypeProductRequest";
import { ProductTypeSaveRequest } from "../../models/product-type/ProductTypeSaveRequest";

export const ProductTypeProductApi = new class ProductTypeProductApi extends ApiBase<ProductTypeProductModel>{
    constructor({ baseUrl }: { baseUrl: string; }) {
        super(baseUrl);
    }
    GetProductTypeGetListAsync(data: ProductTypeProductRequest) {
		return ApiClient.GET(`${this._baseUrl}/san-pham?productType_ID=${data.productTypeID}`);
	}
    GetListProductByCategoryPopUpAsync(data: ProductByCategoryRequest) {
		return ApiClient.GET(`${this._baseUrl}/san-pham-theo-danh-muc?Cat_ID=${data.Cat_Id}`);
	}
    ProductTypeSaveAsync(request: ProductTypeSaveRequest){
        return ApiClient.POST(`${this._baseUrl}/save-product-to-product-type`, request);
    }
}({ baseUrl: '/product-type-product' });