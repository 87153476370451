import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DxTable, { DxTableColumn, DxToolbarItem } from '../../../components/common/DxTable';
import { createUUID } from '../../../utils/createUUID';
import { Button, ButtonLink, LoadingTable } from '../../../components/base';
import no_img from '../../../assets/images/picture.png';
import { BlockCard } from '../../../components/container';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppState } from '../../../context/stores/rootReducers';
import { actions } from '../../../context/stores/rootActions';
import { PreviewImage } from '../../../components/base/ImageBox';
import { ProductImageModel } from '../../../context/models/products-image/ProductImageModel';
import { BaseShowDetail } from '../../../context/base/BaseShowDetail';
import ProductImageDetail from './ProductImageDetail';
import { ConfirmModal } from '../../../components/common/ConfirmModal';
import { ProductModel } from '../../../context/models/products/ProductModel';

interface IProductImageTableProps {
	productId: number;
	title: string;
	description: string;
}

const ProductImageTable = ({ productId, title, description }: IProductImageTableProps) => {
	const dispatch = useDispatch();
	const { status, allProductImages, showConfirm, showDetail, selectedIds } = useSelector((state: AppState) => state.productImage);

	const [showPreview, setShowPreview] = useState({ show: true, image_path: '' });

	const dataSources = useMemo(() => {
		return allProductImages.sort((a, b) => a.sortOrder - b.sortOrder);
	}, [allProductImages]);

	const handleReloadDataWithFilter = useCallback(
		() => {
			dispatch(actions.productImage.getAllRequest(productId));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[productId]
	);

	const initProductImage = useMemo(() => {
		return {
			id: 0,
			productId: productId,
			path: '',
			title: title,
			description: description,
			sortOrder: 0,
		} as ProductImageModel;
	}, [productId, title, description]);

	const handleSaveData = (data: ProductImageModel) => {
		dispatch(actions.productImage.saveRequest(data));
	};

	const handleDeleteData = (ids: number[]) => {
		dispatch(actions.productImage.deleteRequest(ids));
	};

	const onChangeSelectedIds = (ids: number[]) => {
		dispatch(actions.productImage.changeSelectedIds(ids));
	};

	const onShowDetail = (data: BaseShowDetail<ProductImageModel>) => {
		dispatch(actions.productImage.showDetail(data));
	};

	const onShowConfirm = (isShow: boolean) => {
		dispatch(actions.productImage.showConfirm(isShow));
	};

	useEffect(() => {
		handleReloadDataWithFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productId]);

	useEffect(() => {
		if (status == eBaseActionStatus.reload) handleReloadDataWithFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const columns = useMemo((): any => {
		let result: any = [
			<DxTableColumn
				key={createUUID()}
				dataField='path'
				caption={`Ảnh`}
				minWidth={120}
				cellRender={(cell: any) => {
					const article = cell.data;
					return (
						<>
							<div className='user-card'>
								<div className='product-image-avatar bg-white border border-light d-none d-sm-block d-md-block'>
									<img src={article.path?.length > 0 ? article.path : no_img} alt='ảnh đại diện' />
								</div>
								<div className='user-info'>
									<h6 className='tb-lead'>
										<span className='fw-bold'>
											<em className='ni ni-article text-azure fs-14px'></em>
											<span className='bold _neo_article_TITLE'>{article.title}</span>
										</span>
									</h6>
									<p className='tb-sapo d-none d-sm-block d-md-block p-0 m-0 pb-1'>
										Mô tả: <em>{article.description}</em>
									</p>
								</div>
							</div>
						</>
					);
				}}
			></DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={50}
				caption={'Sửa'}
				alignment='center'
				allowExporting={true}
				fixed
				cellRender={(cell: any) => {
					return (
						<>
							<ButtonLink
								onClick={() => onShowDetail({ isShow: true, detailData: cell.data })}
								title='Sửa'
								icon='ni ni-edit'
								theme='link'
							/>
							<ButtonLink
								onClick={() => setShowPreview({ show: true, image_path: cell.data.path })}
								title='Xem trước'
								icon='ni ni-eye'
								theme='link'
							/>
						</>
					);
				}}
			/>
		);
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const toolBars = useMemo(() => {
		let result: any = [
			<DxToolbarItem key={createUUID()} location='before'>
				<Button
					text='Tạo mới'
					icon='icon ni ni-plus'
					theme='primary'
					className='d-md-inline-flex'
					onClick={() => onShowDetail({ isShow: true, detailData: initProductImage })}
				/>
			</DxToolbarItem>,
			<DxToolbarItem key={createUUID()} location='before'>
				<Button
					text={`Xóa (${selectedIds.length})`}
					icon='icon ni ni-trash'
					className='d-md-inline-flex'
					theme='danger'
					isDisabled={selectedIds.length <= 0}
					onClick={() => onShowConfirm(true)}
				/>
			</DxToolbarItem>,
		];
		return result;
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initProductImage, selectedIds, onShowConfirm, onShowDetail]);

	return (
		<>
			<div className='col-sm-12'>
				{productId > 0 && (
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={dataSources}
								keyExpr='id'
								columns={columns}
								toolbars={toolBars}
								hasFixed={true}
								isLoading={status !== eBaseActionStatus.complete}
								defaultPageSize={50}
								height={window.innerHeight - 300}
								wordWrapEnabled={true}
								filters={{
									refreshDataGrid: handleReloadDataWithFilter,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				)}
				{productId <= 0 && <div className='alert alert-warning'>Vui lòng lưu sản phẩm để bổ sung ảnh sản phẩm</div>}
			</div>
			{showDetail && showDetail.isShow && (
				<>
					<ProductImageDetail
						isSaving={status === eBaseActionStatus.saving}
						isShow={showDetail.isShow}
						detailModel={showDetail.detailData}
						onClose={() => onShowDetail({ isShow: false })}
						onSubmit={(data) => handleSaveData(data)}
						title={`${showDetail.detailData ? `Cập nhật` : `Thêm mới`} ảnh sản phẩm`}
					/>
				</>
			)}
			{showConfirm && (
				<>
					<ConfirmModal
						show={showConfirm}
						innerText={`Bạn chắc chắn muốn xóa ${selectedIds.length} ảnh sản phẩm đã chọn ?`}
						type='danger'
						onClose={() => onShowConfirm(false)}
						onConfirmed={() => handleDeleteData(selectedIds)}
						isLoading={status === eBaseActionStatus.loading || status === eBaseActionStatus.saving}
						btnConfirmText='Xóa'
						btnCloseText='Không xóa'
					/>
				</>
			)}
			{showPreview && showPreview.show && (
				<PreviewImage image={showPreview.image_path} onClose={() => setShowPreview({ show: false, image_path: '' })} />
			)}
		</>
	);
};

export default ProductImageTable;
