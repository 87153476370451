import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { AppState } from '../../../context/stores/rootReducers';
import { useEffect } from 'react';
import { actions } from '../../../context/stores/rootActions';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';

export function useLookupCollectionGroup() {
	const dispatch = useDispatch();
	const { status, allCollectionsGroup } = useSelector((state: AppState) => state.collectionGroup);
	useEffect(() => {
		if (status === eBaseActionStatus.idle || status === eBaseActionStatus.reload){
			const data = {typeID:1}
			dispatch(actions.collectionGroup.getAllRequest(data));
		//eslint-disable-next-line react-hooks/exhaustive-deps
		}
	}, []);
	return { collection_group_all: allCollectionsGroup };
}
