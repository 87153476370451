import { ProductImageModel } from "../../models/products-image/ProductImageModel";
import { ApiBase } from "../../base/_ApiBase";
import { ApiClient } from "../../base/_ApiClient";

export const ProductImageApi = new class ProductImageApi extends ApiBase<ProductImageModel>{
    constructor({ baseUrl }: { baseUrl: string; }) {
        super(baseUrl);
    }
    GetAllByProductAsync(product_id: number) {
        return ApiClient.GET(`${this._baseUrl}/all-by-product/${product_id}`);
    }
}({ baseUrl: '/products-image' });