import { BasePaginationRespone } from '../../base/BasePaginationRespone';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { IActionTypeBase } from '../../base/IBaseActionTypes';
import { BannerPositionModel } from '../../models/banners/BannerPositionModel';

export enum eBannerPositionActionTypeIds {
	GET_ALL_REQUEST = 'BannerPosition_GET_ALL_REQUEST',
	GET_ALL_SUCCESS = 'BannerPosition_GET_ALL_SUCCESS',
	GET_ALL_FAILURE = 'BannerPosition_GET_ALL_FAILURE',

	GET_PAGINATION_REQUEST = 'BannerPosition_GET_PAGINATION_REQUEST',
	GET_PAGINATION_SUCCESS = 'BannerPosition_GET_PAGINATION_SUCCESS',
	GET_PAGINATION_FAILURE = 'BannerPosition_GET_PAGINATION_FAILURE',

	SAVE_REQUEST = 'BannerPosition_SAVE_REQUEST',
	SAVE_SUCCESS = 'BannerPosition_SAVE_SUCCESS',
	SAVE_FAILURE = 'BannerPosition_SAVE_FAILURE',

	DELETE_REQUEST = 'BannerPosition_DELETE_REQUEST',
	DELETE_SUCCESS = 'BannerPosition_DELETE_SUCCESS',
	DELETE_FAILURE = 'BannerPosition_DELETE_FAILURE',

	NEED_RELOAD = 'BannerPosition_NEED_RELOAD',

	CHANGE_SELECTED_IDS = 'BannerPosition_CHANGE_SELECTED_IDS',
	SHOW_DETAIL = 'BannerPosition_SHOW_DETAIL',
	SHOW_CONFIRM = 'BannerPosition_SHOW_CONFIRM',
}

export interface BannerPositionAction_GETALL_Request extends IActionTypeBase<eBannerPositionActionTypeIds.GET_ALL_REQUEST, undefined> {}
export interface BannerPositionAction_GETALL_Success
	extends IActionTypeBase<eBannerPositionActionTypeIds.GET_ALL_SUCCESS, BannerPositionModel[]> {}
export interface BannerPositionAction_GETALL_Failure
	extends IActionTypeBase<eBannerPositionActionTypeIds.GET_ALL_FAILURE, Error | string> {}

export interface BannerPositionAction_GETPAGINATION_Request
	extends IActionTypeBase<eBannerPositionActionTypeIds.GET_PAGINATION_REQUEST, BaseSelectRequest> {}
export interface BannerPositionAction_GETPAGINATION_Success
	extends IActionTypeBase<eBannerPositionActionTypeIds.GET_PAGINATION_SUCCESS, BasePaginationRespone<BannerPositionModel>> {}
export interface BannerPositionAction_GETPAGINATION_Failure
	extends IActionTypeBase<eBannerPositionActionTypeIds.GET_PAGINATION_FAILURE, Error | string> {}

export interface BannerPositionAction_SAVE_Request
	extends IActionTypeBase<eBannerPositionActionTypeIds.SAVE_REQUEST, BannerPositionModel> {}
export interface BannerPositionAction_SAVE_Success extends IActionTypeBase<eBannerPositionActionTypeIds.SAVE_SUCCESS, string> {}
export interface BannerPositionAction_SAVE_Failure extends IActionTypeBase<eBannerPositionActionTypeIds.SAVE_FAILURE, Error | string> {}

export interface BannerPositionAction_DELETE_Request extends IActionTypeBase<eBannerPositionActionTypeIds.DELETE_REQUEST, number[]> {}
export interface BannerPositionAction_DELETE_Success extends IActionTypeBase<eBannerPositionActionTypeIds.DELETE_SUCCESS, string> {}
export interface BannerPositionAction_DELETE_Failure extends IActionTypeBase<eBannerPositionActionTypeIds.DELETE_FAILURE, Error | string> {}

export interface BannerPositionAction_RELOAD extends IActionTypeBase<eBannerPositionActionTypeIds.NEED_RELOAD, undefined> {}

export interface BannerPositionAction_SHOW_DETAIL
	extends IActionTypeBase<eBannerPositionActionTypeIds.SHOW_DETAIL, BaseShowDetail<BannerPositionModel>> {}
export interface BannerPositionAction_CHANGE_SELECTED_IDS
	extends IActionTypeBase<eBannerPositionActionTypeIds.CHANGE_SELECTED_IDS, number[]> {}
export interface BannerPositionAction_SHOW_CONFIRM extends IActionTypeBase<eBannerPositionActionTypeIds.SHOW_CONFIRM, boolean> {}

export type BannerPositionActionTypes =
	| BannerPositionAction_GETALL_Request
	| BannerPositionAction_GETALL_Success
	| BannerPositionAction_GETALL_Failure
	| BannerPositionAction_GETPAGINATION_Request
	| BannerPositionAction_GETPAGINATION_Success
	| BannerPositionAction_GETPAGINATION_Failure
	| BannerPositionAction_SAVE_Request
	| BannerPositionAction_SAVE_Success
	| BannerPositionAction_SAVE_Failure
	| BannerPositionAction_DELETE_Request
	| BannerPositionAction_DELETE_Success
	| BannerPositionAction_DELETE_Failure
	| BannerPositionAction_RELOAD
	| BannerPositionAction_SHOW_DETAIL
	| BannerPositionAction_CHANGE_SELECTED_IDS
	| BannerPositionAction_SHOW_CONFIRM;
