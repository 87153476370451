import { KeywordSeoActionTypes, eKeywordSeoActionTypeIds } from './IKeywordSeoActionsTypes';
import { BaseGetActionStatus, eBaseActionStatus } from '../../base/eBaseActionStatus';
import { NotifyHelper } from '../../../utils/NotifyHelper';
import { IKeywordSeoState } from './IKeywordSeoState';
import { BasePaginationRespone, dfBasePagination } from '../../base/BasePaginationRespone';
import { dfBaseShowDetail } from '../../base/BaseShowDetail';
import { KeywordSeoModel } from '../../models/crm/KeywordSeoModel';

const initPaginationResponse: BasePaginationRespone<KeywordSeoModel> = { listDatas: [], pagination: dfBasePagination };
const initialState: IKeywordSeoState = {
	status: eBaseActionStatus.idle,
	selectedIds: [],
	allKeywordSeo: [],
	showConfirm: false,
	paginationResponse: initPaginationResponse,
	showDetail: dfBaseShowDetail,
};
const KeywordSeoReducer = (state: IKeywordSeoState = initialState, action: KeywordSeoActionTypes): IKeywordSeoState => {
	switch (action.type) {
		case eKeywordSeoActionTypeIds.GET_KEYWORD_REQUEST:
		case eKeywordSeoActionTypeIds.GET_ALL_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eKeywordSeoActionTypeIds.GET_KEYWORD_SUCCESS:
			return {
				...state,
				allKeywordSeo: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eKeywordSeoActionTypeIds.GET_ALL_SUCCESS:
			return {
				...state,
				allKeywordSeo: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eKeywordSeoActionTypeIds.GET_KEYWORD_FAILURE:
			return {
				...state,
				allKeywordSeo: [],
				status: BaseGetActionStatus(action),
			};
		case eKeywordSeoActionTypeIds.GET_ALL_FAILURE:
			return {
				...state,
				allKeywordSeo: [],
				status: BaseGetActionStatus(action),
			};
		case eKeywordSeoActionTypeIds.CHANGE_SELECTED_IDS:
			return {
				...state,
				selectedIds: action.payload,
			};
		case eKeywordSeoActionTypeIds.SHOW_DETAIL:
			return {
				...state,
				showDetail: action.payload,
			};
		case eKeywordSeoActionTypeIds.SHOW_CONFIRM:
			return {
				...state,
				showConfirm: action.payload,
			};
		case eKeywordSeoActionTypeIds.NEED_RELOAD:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		default:
			return state;
	}
};
export default KeywordSeoReducer;
