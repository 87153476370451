import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, ButtonLink, LoadingTable } from '../../components/base';
import { ConfirmModal } from '../../components/common/ConfirmModal';
import DxTable, { DxLookup, DxTableColumn } from '../../components/common/DxTable';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../components/container';
import { BaseShowDetail } from '../../context/base/BaseShowDetail';
import { eBaseActionStatus } from '../../context/base/eBaseActionStatus';
import { actions } from '../../context/stores/rootActions';
import { AppState } from '../../context/stores/rootReducers';
import { createUUID } from '../../utils/createUUID';
import { CollectionGroupModel } from '../../context/models/collections/CollectionGroupModel';
import CollectionGroupDetail from './CollectionGroupDetail';
import { CollectionGroupActionTypes } from '../../context/stores/collections-group/ICollectionGroupActionsTypes';
import { useLookupCollectionType } from '../../components/shared/hoc-collection-type/useLookupCollectionType';
import ComboBoxCollectionType from '../../components/shared/hoc-collection-type/ComboBoxCollectionType';
import { CollectionGroupResponse } from '../../context/models/collections/CollectionGroupResponse';
import { useNavigate } from 'react-router-dom';
// import ArticleCategoryDetail from './ArticleCategoryDetail';
type ICollectionGroupPageProps = {
	titlePage: string;
	current_type_id: number;
	status: eBaseActionStatus;
	allCollectionsGroup: CollectionGroupResponse[];
	selectedIds: number[];
	showDetail: BaseShowDetail<CollectionGroupModel>;
	showConfirm: boolean;
	handleReloadAllData: (type_id: number) => void;
	handleSaveData: (data: CollectionGroupModel) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	onShowDetail: (data: BaseShowDetail<CollectionGroupModel>) => void;
	onShowConfirm: (isShow: boolean) => void;
};

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `vị trí hiển thị`,
		status: state.collectionGroup.status,
		current_type_id: parseInt(localStorage.getItem('current_type_id') || '1'),
		allCollectionsGroup: state.collectionGroup.allCollectionsGroup,
		selectedIds: state.collectionGroup.selectedIds,
		showDetail: state.collectionGroup.showDetail,
		showConfirm: state.collectionGroup.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<CollectionGroupActionTypes>) => ({
	handleReloadAllData: (typeID: number) => {
		dispatch(actions.collectionGroup.getAllRequest({ typeID }));
	},
	handleSaveData: (data: CollectionGroupModel) => {
		dispatch(actions.collectionGroup.saveRequest(data));
	},
	handleDeleteData: (ids: number[]) => {
		dispatch(actions.collectionGroup.deleteRequest(ids));
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.collectionGroup.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetail<CollectionGroupModel>) => {
		dispatch(actions.collectionGroup.showDetail(data));
	},
	onShowConfirm: (isShow: boolean) => {
		dispatch(actions.collectionGroup.showConfirm(isShow));
	},
});

const CollectionGroupPage = (props: ICollectionGroupPageProps) => {
	const navigate = useNavigate();
	const {
		titlePage,
		status,
		selectedIds,
		showConfirm,
		showDetail,
		allCollectionsGroup,
		handleReloadAllData,
		handleDeleteData,
		onChangeSelectedIds,
		handleSaveData,
		onShowDetail,
		onShowConfirm,
		current_type_id,
	} = props;
	allCollectionsGroup.sort((a, b) => a.stt - b.stt);
	const [typeID, setTypeID] = useState<number>(current_type_id);

	const { collection_type_all } = useLookupCollectionType();

	var handleReloadData = useCallback(() => {
		handleReloadAllData(typeID);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [typeID]);

	useEffect(() => {
		handleReloadData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [typeID]);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const columns = useMemo((): any => {
		let result: any = [
			<DxTableColumn key={createUUID()} dataField='stt' caption={`Số thứ tự`} alignment='center' width={100} fixed></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='type' width={150} caption={`Vị trí`}>
				<DxLookup dataSource={collection_type_all} valueExpr={'id'} displayExpr={'name'} />
			</DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='name' caption={`Tên nhóm`} minWidth={150} fixed></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='title' minWidth={150} caption={`Tiêu đề`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='sapo' width={150} caption={`Sapo`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='icon' width={150} caption={`Icon`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='image' width={150} caption={`Ảnh`}></DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={150}
				caption={'Sửa'}
				alignment='center'
				allowExporting={true}
				fixed
				cellRender={(cell: any) => {
					return (
						<>
							{cell.data.id > 0 && (
								<>
									<Button
										onClick={() => {
											localStorage.setItem('current_group_id', cell.data.id);
											navigate(`/collections`, { replace: true });
										}}
										text=''
										icon='ni ni-grid-box-alt-fill'
										preset='dimoutline'
										theme='info'
										title='Bộ sưu tập thuộc nhóm'
									/>{' '}
									<Button
										onClick={() => onShowDetail({ isShow: true, detailData: cell.data })}
										text=''
										icon='ni ni-edit'
										preset='dimoutline'
										theme='primary'
										title='Sửa'
									/>{' '}
								</>
							)}
						</>
					);
				}}
			/>
		);
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collection_type_all]);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							{selectedIds.length > 0 && (
								<li>
									<Button
										text={`Xóa (${selectedIds.length})`}
										icon='icon ni ni-trash'
										className='d-md-inline-flex'
										theme='danger'
										onClick={() => onShowConfirm(true)}
									/>
								</li>
							)}

							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => onShowDetail({ isShow: true })}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						<li className='col col-lg-3'>
							<ComboBoxCollectionType
								name={'typeID'}
								onValueChanged={(value) => {
									setTypeID(value ? parseInt(value) : 0);
								}}
								value={typeID}
								placeholder='Nhập vị trí...'
								sizeClass='xs'
							/>
						</li>
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={allCollectionsGroup}
								keyExpr='id'
								columns={columns}
								hasFixed={true}
								isLoading={status !== eBaseActionStatus.complete}
								defaultPageSize={50}
								filters={{
									refreshDataGrid: handleReloadData,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
			</PageContainer>
			{showDetail && showDetail.isShow && (
				<>
					<CollectionGroupDetail
						isSaving={status === eBaseActionStatus.saving}
						isShow={showDetail.isShow}
						detailModel={showDetail.detailData}
						onClose={() => onShowDetail({ isShow: false })}
						onSubmit={(data) => handleSaveData(data)}
						title={`${showDetail.detailData ? `Cập nhật` : `Thêm mới`} ${titlePage}`}
					/>
				</>
			)}
			{showConfirm && (
				<>
					<ConfirmModal
						show={showConfirm}
						innerText={`Bạn chắc chắn muốn xóa ${selectedIds.length} ${titlePage.toLowerCase()} đã chọn ?`}
						type='danger'
						onClose={() => onShowConfirm(false)}
						onConfirmed={() => handleDeleteData(selectedIds)}
						isLoading={status === eBaseActionStatus.loading || status === eBaseActionStatus.saving}
						btnConfirmText='Xóa'
						btnCloseText='Không xóa'
					/>
				</>
			)}
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionGroupPage);
