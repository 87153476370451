import * as Yup from 'yup';
export type CollectionTypeModel = {
    id: number;
    name: string;
    note: string;
    stt: number;
}

export const dfCollectionTypeModel: CollectionTypeModel = {
    id: 0,
    name: '',
    note: '',
    stt: 0,
}

export const validCollectionTypeSchema = () => Yup.object().shape({
    name: Yup.string().required('Vui lòng nhập vị trí Bộ sưu tập.'),
});