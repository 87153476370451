import { all, call, put, fork, takeEvery } from 'redux-saga/effects';
import { actions } from '../rootActions';
import {
	ProductTypeProductAction_DELETE_Request,
	ProductTypeProductAction_GETALL_Request,
	ProductTypeProductAction_GETBY_CATEGORY_Request,
	ProductTypeProductAction_GETPAGINATION_Request,
	ProductTypeProductAction_SAVEPRODUCT_Request,
	ProductTypeProductAction_SAVE_Request,
	eProductTypeProductActionTypeIds,
} from './IProductTypeProductActionsTypes';
import { BaseResponse } from '../../base/BaseResponse';
import { rootApi } from '../../api/rootApi';
import { ProductTypeProductResponse } from '../../models/product-type/ProductTypeProductResponse';

const _sagaApi = rootApi.productTypeProduct;

function* onLoadAllProductTypeProduct(action: ProductTypeProductAction_GETALL_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetProductTypeGetListAsync],action.payload);
        if (res.isSuccess && res.result) {
            const listCollection = res.result as ProductTypeProductResponse[];
            yield put(actions.productTypeProduct.getAllSuccess(listCollection));
        }
        else {
            yield put(actions.productTypeProduct.getAllFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.productTypeProduct.getAllFailure(error || 'Có lỗi'));
    }
}

function* onLoadByCategoryProductTypeProduct(action: ProductTypeProductAction_GETBY_CATEGORY_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetListProductByCategoryPopUpAsync],action.payload);
        if (res.isSuccess && res.result) {
            const listCollection = res.result as ProductTypeProductResponse[];
            yield put(actions.productTypeProduct.getByCategorySuccess(listCollection));
        }
        else {
            yield put(actions.productTypeProduct.getByCategoryFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.productTypeProduct.getByCategoryFailure(error || 'Có lỗi'));
    }
}

function* onLoadPaginationProductTypeProduct(action: ProductTypeProductAction_GETPAGINATION_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetPaginationAsync], action.payload);
		if (res.isSuccess && res.result) {
			const listProductTypeProduct = res.result as ProductTypeProductResponse[];
			yield put(actions.productTypeProduct.getPaginationSuccess({ listDatas: listProductTypeProduct, pagination: res.pagination }));
		} else {
			yield put(actions.productTypeProduct.getPaginationFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.productTypeProduct.getPaginationFailure(error || 'Có lỗi'));
	}
}

function* onSaveProductTypeProduct(action: ProductTypeProductAction_SAVE_Request) {
	try {
		if (action.payload.id > 0) {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.productTypeProduct.saveSuccess(res.message));
				yield put(actions.productTypeProduct.needReload());
			} else {
				yield put(actions.productTypeProduct.saveFailure(res.message));
			}
		} else {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.productTypeProduct.saveSuccess(res.message));
				yield put(actions.productTypeProduct.needReload());
			} else {
				yield put(actions.productTypeProduct.saveFailure(res.message));
			}
		}
	} catch (error: any) {
		yield put(actions.productTypeProduct.saveFailure(error || 'Có lỗi'));
	}
}

function* onDeleteProductTypeProduct(action: ProductTypeProductAction_DELETE_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
		if (res.isSuccess) {
			yield put(actions.productTypeProduct.deleteSuccess(res.message));
			yield put(actions.productTypeProduct.needReload());
		} else {
			yield put(actions.productTypeProduct.deleteFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.productTypeProduct.deleteFailure(error || 'Có lỗi'));
	}
}

function* onProductTypeSaveProduct(action: ProductTypeProductAction_SAVEPRODUCT_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.ProductTypeSaveAsync], action.payload);
        if (res.isSuccess) {
            yield put(actions.productTypeProduct.productTypeSaveSuccess(res.message));
        }
        else {
            yield put(actions.productTypeProduct.productTypeSaveFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.productTypeProduct.productTypeSaveFailure(error || 'Có lỗi'));
    }
}

function* watchOnLoadAllProductTypeProduct() {
	yield takeEvery(eProductTypeProductActionTypeIds.GET_ALL_REQUEST, onLoadAllProductTypeProduct);
}

function* watchOnLoadByCategoryProductTypeProduct() {
	yield takeEvery(eProductTypeProductActionTypeIds.GET_BY_CATEGORY_REQUEST, onLoadByCategoryProductTypeProduct);
}

function* watchOnLoadPaginationProductTypeProduct() {
	yield takeEvery(eProductTypeProductActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationProductTypeProduct);
}

function* watchOnSaveProductTypeProduct() {
	yield takeEvery(eProductTypeProductActionTypeIds.SAVE_REQUEST, onSaveProductTypeProduct);
}

function* watchOnDeleteProductTypeProduct() {
	yield takeEvery(eProductTypeProductActionTypeIds.DELETE_REQUEST, onDeleteProductTypeProduct);
}
function* watchOnProductTypeSaveProduct() {
	yield takeEvery(eProductTypeProductActionTypeIds.PRODUCT_TYPE_SAVE_REQUEST, onProductTypeSaveProduct);
}

function* productTypeProductSaga() {
	yield all([fork(watchOnLoadAllProductTypeProduct)]);
	yield all([fork(watchOnLoadByCategoryProductTypeProduct)]);
	yield all([fork(watchOnLoadPaginationProductTypeProduct)]);
	yield all([fork(watchOnSaveProductTypeProduct)]);
	yield all([fork(watchOnDeleteProductTypeProduct)]);
	yield all([fork(watchOnProductTypeSaveProduct)]);
}

export default productTypeProductSaga;
