import { all, call, put, fork, takeEvery } from "redux-saga/effects";
import { actions } from "../rootActions";
import { BannerAction_DELETE_Request, BannerAction_GETALL_Request, BannerAction_GETPAGINATION_Request, BannerAction_GET_DETAIL_Request, BannerAction_SAVE_Request, eBannerActionTypeIds } from "./IBannerActionsTypes";
import { BaseResponse } from "../../base/BaseResponse";
import { rootApi } from "../../api/rootApi";
import { BannerModel } from "../../models/banners/BannerModel";

const _sagaApi = rootApi.banner;

function* onLoadAllBanner(action: BannerAction_GETALL_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetAllAsync]);
        if (res.isSuccess && res.result) {
            const listBanner = res.result as BannerModel[];
            yield put(actions.banner.getAllSuccess(listBanner));
        }
        else {
            yield put(actions.banner.getAllFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.banner.getAllFailure(error || 'Có lỗi'));
    }
}

function* onLoadPaginationBanner(action: BannerAction_GETPAGINATION_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetPaginationAsync], action.payload);
        if (res.isSuccess && res.result) {
            const listBanner = res.result as BannerModel[];
            yield put(actions.banner.getPaginationSuccess({ listDatas: listBanner, pagination: res.pagination }));
        }
        else {
            yield put(actions.banner.getPaginationFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.banner.getPaginationFailure(error || 'Có lỗi'));
    }
}

function* onSaveBanner(action: BannerAction_SAVE_Request) {
    try {
        if (action.payload.id > 0) {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.banner.saveSuccess(res.message));
                yield put(actions.banner.needReload());
            }
            else {
                yield put(actions.banner.saveFailure(res.message));
            }
        } else {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.banner.saveSuccess(res.message));
                yield put(actions.banner.needReload());
            }
            else {
                yield put(actions.banner.saveFailure(res.message));
            }
        }
    } catch (error: any) {
        yield put(actions.banner.saveFailure(error || 'Có lỗi'));
    }
}

function* onLoadDetailBanner(action: BannerAction_GET_DETAIL_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetByIdAsync], action.payload);
        if (res.isSuccess && res.result) {
            const detail = res.result as BannerModel;
            yield put(actions.banner.detailSuccess(detail));
        }
        else {
            yield put(actions.banner.detailFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.banner.detailFailure(error || 'Có lỗi'));
    }
}

function* onDeleteBanner(action: BannerAction_DELETE_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
        if (res.isSuccess) {
            yield put(actions.banner.deleteSuccess(res.message));
            yield put(actions.banner.needReload());
        }
        else {
            yield put(actions.banner.deleteFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.banner.deleteFailure(error || 'Có lỗi'));
    }
}

function* watchOnLoadAllBanner() {
    yield takeEvery(eBannerActionTypeIds.GET_ALL_REQUEST, onLoadAllBanner);
}

function* watchOnLoadPaginationBanner() {
    yield takeEvery(eBannerActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationBanner);
}


function* watchOnSaveBanner() {
    yield takeEvery(eBannerActionTypeIds.SAVE_REQUEST, onSaveBanner);
}

function* watchOnLoadDetailBanner() {
    yield takeEvery(eBannerActionTypeIds.GET_DETAIL_REQUEST, onLoadDetailBanner);
}

function* watchOnDeleteBanner() {
    yield takeEvery(eBannerActionTypeIds.DELETE_REQUEST, onDeleteBanner);
}

function* bannerSaga() {
    yield all([fork(watchOnLoadAllBanner)]);
    yield all([fork(watchOnLoadPaginationBanner)]);
    yield all([fork(watchOnSaveBanner)]);
    yield all([fork(watchOnLoadDetailBanner)]);
    yield all([fork(watchOnDeleteBanner)]);
}

export default bannerSaga;