import { BasePaginationRespone } from '../../base/BasePaginationRespone';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { BaseAction } from '../../base/IBaseActionRespone';
import { ProductByCategoryRequest } from '../../models/product-type/ProductByCategoryRequest';
import { ProductByCategoryResponse } from '../../models/product-type/ProductByCategoryResponse';
import { ProductTypeProductModel } from '../../models/product-type/ProductTypeProductModel';
import { ProductTypeProductRequest } from '../../models/product-type/ProductTypeProductRequest';
import { ProductTypeProductResponse } from '../../models/product-type/ProductTypeProductResponse';
import { ProductTypeSaveRequest } from '../../models/product-type/ProductTypeSaveRequest';
import {
	ProductTypeProductAction_CHANGE_SELECTED_IDS,
	ProductTypeProductAction_CHANGE_SELECTED_PRODUCT_IDS,
	ProductTypeProductAction_DELETE_Failure,
	ProductTypeProductAction_DELETE_Request,
	ProductTypeProductAction_DELETE_Success,
	ProductTypeProductAction_GETALL_Failure,
	ProductTypeProductAction_GETALL_Request,
	ProductTypeProductAction_GETALL_Success,
	ProductTypeProductAction_GETBY_CATEGORY_Failure,
	ProductTypeProductAction_GETBY_CATEGORY_Request,
	ProductTypeProductAction_GETBY_CATEGORY_Success,
	ProductTypeProductAction_GETPAGINATION_Failure,
	ProductTypeProductAction_GETPAGINATION_Request,
	ProductTypeProductAction_GETPAGINATION_Success,
	ProductTypeProductAction_RELOAD,
	ProductTypeProductAction_SAVEPRODUCT_Failure,
	ProductTypeProductAction_SAVEPRODUCT_Request,
	ProductTypeProductAction_SAVEPRODUCT_Success,
	ProductTypeProductAction_SAVE_Failure,
	ProductTypeProductAction_SAVE_Request,
	ProductTypeProductAction_SAVE_Success,
	ProductTypeProductAction_SHOW_CONFIRM,
	ProductTypeProductAction_SHOW_DETAIL,
	eProductTypeProductActionTypeIds,
} from './IProductTypeProductActionsTypes';

export const productTypeProductActions = {
	//ALL
	getAllRequest: (data: ProductTypeProductRequest): ProductTypeProductAction_GETALL_Request =>
		BaseAction(eProductTypeProductActionTypeIds.GET_ALL_REQUEST, data),
	getAllSuccess: (data: ProductTypeProductResponse[]): ProductTypeProductAction_GETALL_Success =>
		BaseAction(eProductTypeProductActionTypeIds.GET_ALL_SUCCESS, data),
	getAllFailure: (error: Error | string): ProductTypeProductAction_GETALL_Failure =>
		BaseAction(eProductTypeProductActionTypeIds.GET_ALL_FAILURE, error),
	//BY CATEGORY
	getByCategoryRequest: (data: ProductByCategoryRequest): ProductTypeProductAction_GETBY_CATEGORY_Request =>
		BaseAction(eProductTypeProductActionTypeIds.GET_BY_CATEGORY_REQUEST, data),
	getByCategorySuccess: (data: ProductTypeProductResponse[]): ProductTypeProductAction_GETBY_CATEGORY_Success =>
		BaseAction(eProductTypeProductActionTypeIds.GET_BY_CATEGORY_SUCCESS, data),
	getByCategoryFailure: (error: Error | string): ProductTypeProductAction_GETBY_CATEGORY_Failure =>
		BaseAction(eProductTypeProductActionTypeIds.GET_BY_CATEGORY_FAILURE, error),
	//LIST
	getPaginationRequest: (request: BaseSelectRequest): ProductTypeProductAction_GETPAGINATION_Request =>
		BaseAction(eProductTypeProductActionTypeIds.GET_PAGINATION_REQUEST, request),
	getPaginationSuccess: (data: BasePaginationRespone<ProductTypeProductResponse>): ProductTypeProductAction_GETPAGINATION_Success =>
		BaseAction(eProductTypeProductActionTypeIds.GET_PAGINATION_SUCCESS, data),
	getPaginationFailure: (error: Error | string): ProductTypeProductAction_GETPAGINATION_Failure =>
		BaseAction(eProductTypeProductActionTypeIds.GET_PAGINATION_FAILURE, error),
	//SAVE
	saveRequest: (entity: ProductTypeProductResponse): ProductTypeProductAction_SAVE_Request =>
		BaseAction(eProductTypeProductActionTypeIds.SAVE_REQUEST, entity),
	saveSuccess: (message: string): ProductTypeProductAction_SAVE_Success =>
		BaseAction(eProductTypeProductActionTypeIds.SAVE_SUCCESS, message),
	saveFailure: (error: Error | string): ProductTypeProductAction_SAVE_Failure =>
		BaseAction(eProductTypeProductActionTypeIds.SAVE_FAILURE, error),
	//DELETE
	deleteRequest: (ids: number[]): ProductTypeProductAction_DELETE_Request =>
		BaseAction(eProductTypeProductActionTypeIds.DELETE_REQUEST, ids),
	deleteSuccess: (message: string): ProductTypeProductAction_DELETE_Success =>
		BaseAction(eProductTypeProductActionTypeIds.DELETE_SUCCESS, message),
	deleteFailure: (error: Error | string): ProductTypeProductAction_DELETE_Failure =>
		BaseAction(eProductTypeProductActionTypeIds.DELETE_FAILURE, error),
	//PRODUCT_TYPE_SAVE
	productTypeSaveRequest: (data: ProductTypeSaveRequest): ProductTypeProductAction_SAVEPRODUCT_Request =>
		BaseAction(eProductTypeProductActionTypeIds.PRODUCT_TYPE_SAVE_REQUEST, data),
	productTypeSaveSuccess: (message: string): ProductTypeProductAction_SAVEPRODUCT_Success =>
		BaseAction(eProductTypeProductActionTypeIds.PRODUCT_TYPE_SAVE_SUCCESS, message),
	productTypeSaveFailure: (error: Error | string): ProductTypeProductAction_SAVEPRODUCT_Failure =>
		BaseAction(eProductTypeProductActionTypeIds.PRODUCT_TYPE_SAVE_FAILURE, error),
	//RELOAD
	needReload: (): ProductTypeProductAction_RELOAD => BaseAction(eProductTypeProductActionTypeIds.NEED_RELOAD, undefined),
	//ACTIONs
	changeSelectedIds: (ids: number[]): ProductTypeProductAction_CHANGE_SELECTED_IDS =>
		BaseAction(eProductTypeProductActionTypeIds.CHANGE_SELECTED_IDS, ids),
	changeSelectedProductIds: (ids: number[]): ProductTypeProductAction_CHANGE_SELECTED_PRODUCT_IDS =>
		BaseAction(eProductTypeProductActionTypeIds.CHANGE_SELECTED_PRODUCT_IDS, ids),
	showDetail: (detail: BaseShowDetail<ProductTypeProductResponse>): ProductTypeProductAction_SHOW_DETAIL =>
		BaseAction(eProductTypeProductActionTypeIds.SHOW_DETAIL, detail),
	showConfirm: (show: boolean): ProductTypeProductAction_SHOW_CONFIRM => BaseAction(eProductTypeProductActionTypeIds.SHOW_CONFIRM, show),
};
