import React, { useEffect, useState } from 'react';
import {
	ButtonCancel,
	ButtonSave,
	BaseForm,
	FormGroup,
	ModalBody,
	ModalDraggable,
	ModalFooter,
	ModalHeader,
	NumberBox,
	SwitchBox,
	TextBox,
} from '../../components/base';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import { slugHelper } from '../../utils/slugHelper';
import {
	CollectionTypeModel,
	dfCollectionTypeModel,
	validCollectionTypeSchema,
} from '../../context/models/collections/CollectionTypeModel';

type ICollectionTypeDetailModalProps = {
	isShow: boolean;
	title: string;
	detailModel?: CollectionTypeModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: CollectionTypeModel) => void;
};

const CollectionTypeDetail = (props: ICollectionTypeDetailModalProps) => {
	const { isShow, title, detailModel, onSubmit, onClose, isSaving } = props;
	const isAddMode = !detailModel;
	const [initialValues, setInitialValues] = useState<CollectionTypeModel>(dfCollectionTypeModel);

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors, isSubmitting },
	} = useForm<CollectionTypeModel>({
		resolver: yupResolver(validCollectionTypeSchema()),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: CollectionTypeModel) => {
		onSubmit(values);
	};

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues, reset]);

	useEffect(() => {
		if (detailModel) {
			setInitialValues((prev) => ({ ...prev, ...detailModel }));
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailModel]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='sm' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
						<NumberBox
							name={'stt'}
							error={errors.stt?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, stt: value }));
							}}
							value={initialValues.stt}
							hasValid={true}
							label='Số thứ tự'
						/>
						<TextBox
							name={'name'}
							error={errors.name?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, name: value }));
							}}
							value={initialValues.name}
							hasValid={true}
							label='Tên vị trí'
						/>
						<TextBox
							name={'note'}
							error={errors.note?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, note: value }));
							}}
							value={initialValues.note}
							hasValid={true}
							label='Mô tả'
							// helpBlock={`Link truy cập nội dung: <code>ten-chuyen-muc</code>. Hoặc nhập tên vào hệ thống sẽ tự tạo.`}
						/>

						{/* <SwitchBox
							name='isDelete'
							label='Áp dụng'
							error={errors.isDelete?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, isDelete: value }));
							}}
							value={initialValues.isDelete}
							sizeClass='sm'
						/> */}
					</BaseForm>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave
						type='button'
						isLoading={isSaving}
						isDisabled={isSaving}
						onClick={handleSubmit(onSubmitHandler)}
						text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default CollectionTypeDetail;
