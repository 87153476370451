import { useCallback, useEffect, useMemo, useState } from 'react';
import { ButtonCancel, ButtonSave, LoadingTable, ModalBody, ModalDraggable, ModalFooter, ModalHeader } from '../../components/base';

import { useDispatch, useSelector } from 'react-redux';
import DxTable, { DxTableColumn, DxToolbarItem } from '../../components/common/DxTable';
import ComboBoxProductCategory from '../../components/shared/hoc-product-category/ComboBoxProductCategory';
import { no_img } from '../../components/shared/hoc-static';
import { eBaseActionStatus } from '../../context/base/eBaseActionStatus';
import { actions } from '../../context/stores/rootActions';
import { AppState } from '../../context/stores/rootReducers';
import { createUUID } from '../../utils/createUUID';

type IProductTypeProductModalProps = {
	isShow: boolean;
	isSaving: boolean;
	productTypeId: number;
	isDisabled?: boolean;
	onClose: () => void;
};

const ProductTypeProductModal = (props: IProductTypeProductModalProps) => {
	const { isShow, isSaving, onClose, productTypeId } = props;
	const dispatch = useDispatch();
	const [filterModal, setFilterModal] = useState({
		catId: 0,
	});

	const { allProductByCategory } = useSelector((state: AppState) => state.productTypeProduct);
	const { statusCat, selectedProductIds } = useSelector((state: AppState) => state.productTypeProduct);

	var handleChangeProductIds = useCallback((productIds: number[]) => {
		dispatch(actions.productTypeProduct.changeSelectedProductIds(productIds || []));
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const onSubmitHandler = (values: ProductTypeProductResponse) => {
	// 	onSubmit(values);
	// };

	const handleReloadData = useCallback(() => {
		dispatch(
			actions.productTypeProduct.getByCategoryRequest({
				Cat_Id: filterModal.catId,
			})
		);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterModal.catId]);

	const handleSaveData = useCallback(() => {
		if (selectedProductIds.length > 0) {
			dispatch(
				actions.productTypeProduct.productTypeSaveRequest({
					IDProducts: selectedProductIds,
					IDProductType: productTypeId,
				})
			);
			onClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productTypeId, selectedProductIds]);

	useEffect(() => {
		handleReloadData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterModal.catId]);

	// useEffect(() => {
	// 	if (statusCat === eBaseActionStatus.reload){
	// 		onClose();
	// 	}
	// 	//eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [statusCat]);

	const columns = useMemo((): any => {
		let result: any = [
			<DxTableColumn key={createUUID()} dataField='name' caption={`Tên sản phẩm`} width={200} alignment={'center'}></DxTableColumn>,
			<DxTableColumn
				key={createUUID()}
				dataField='image'
				width={100}
				caption={`Ảnh`}
				cellRender={(cell: any) => {
					const picture = cell.data;
					return (
						<>
							<div className='user-card'>
								<div className='banner-avatar bg-white border border-light d-none d-sm-block d-md-block'>
									<img src={picture.image?.length > 0 ? picture.image : no_img} alt='ảnh' />
								</div>
							</div>
						</>
					);
				}}
			></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='sku' minWidth={150} caption={`SKU`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='price' minWidth={150} caption={`Giá`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='priceOnSale' minWidth={150} caption={`Giá đã giảm`}></DxTableColumn>,
		];
		return result;
	}, []);

	const toolBars = useMemo(() => {
		let result: any = [
			<DxToolbarItem key={createUUID()} location='before'>
				<ComboBoxProductCategory
					name={'catId'}
					onValueChanged={(value) => {
						setFilterModal((prev) => ({ ...prev, catId: value }));
					}}
					value={filterModal.catId}
					hasValid={true}
					// label='Danh mục sản phẩm'
				/>
			</DxToolbarItem>,
		];
		return result;
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterModal]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='xl' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={'Danh sách sản phẩm'} onClose={() => onClose()} />
				<ModalBody>
					{statusCat === eBaseActionStatus.loading && <LoadingTable />}
					{statusCat !== eBaseActionStatus.loading && (
						<DxTable
							dataSource={allProductByCategory}
							keyExpr='id'
							columns={columns}
							toolbars={toolBars}
							defaultPageSize={50}
							height={window.innerHeight - 300}
							filters={{
								refreshDataGrid: handleReloadData,
							}}
							selection={{
								mode: 'multiple',
								onSelectionChanged: (e: any) => {
									handleChangeProductIds(e.selectedRowKeys || []);
								},
								selectedRowKeys: selectedProductIds,
							}}
						/>
					)}
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave type='button' isLoading={isSaving} isDisabled={isSaving} onClick={() => handleSaveData()} text={'Thêm sản phẩm'} />
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default ProductTypeProductModal;
