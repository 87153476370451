import { all, call, put, fork, takeEvery } from 'redux-saga/effects';
import { actions } from '../rootActions';
import {
	ProductTypeAction_DELETE_Request,
	ProductTypeAction_GETALL_Request,
	ProductTypeAction_GETPAGINATION_Request,
	ProductTypeAction_SAVE_Request,
	eProductTypeActionTypeIds,
} from './IProductTypeActionsTypes';
import { BaseResponse } from '../../base/BaseResponse';
import { rootApi } from '../../api/rootApi';
import { ProductTypeModel } from '../../models/product-type/ProductTypeModel';

const _sagaApi = rootApi.productType;

function* onLoadAllProductType(action: ProductTypeAction_GETALL_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetAllAsync]);
		if (res.isSuccess && res.result) {
			const listProductType = res.result as ProductTypeModel[];
			yield put(actions.productType.getAllSuccess(listProductType));
		} else {
			yield put(actions.productType.getAllFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.productType.getAllFailure(error || 'Có lỗi'));
	}
}

function* onLoadPaginationProductType(action: ProductTypeAction_GETPAGINATION_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetPaginationAsync], action.payload);
		if (res.isSuccess && res.result) {
			const listProductType = res.result as ProductTypeModel[];
			yield put(actions.productType.getPaginationSuccess({ listDatas: listProductType, pagination: res.pagination }));
		} else {
			yield put(actions.productType.getPaginationFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.productType.getPaginationFailure(error || 'Có lỗi'));
	}
}

function* onSaveProductType(action: ProductTypeAction_SAVE_Request) {
	try {
		if (action.payload.id > 0) {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.productType.saveSuccess(res.message));
				yield put(actions.productType.needReload());
			} else {
				yield put(actions.productType.saveFailure(res.message));
			}
		} else {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.productType.saveSuccess(res.message));
				yield put(actions.productType.needReload());
			} else {
				yield put(actions.productType.saveFailure(res.message));
			}
		}
	} catch (error: any) {
		yield put(actions.productType.saveFailure(error || 'Có lỗi'));
	}
}

function* onDeleteProductType(action: ProductTypeAction_DELETE_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
		if (res.isSuccess) {
			yield put(actions.productType.deleteSuccess(res.message));
			yield put(actions.productType.needReload());
		} else {
			yield put(actions.productType.deleteFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.productType.deleteFailure(error || 'Có lỗi'));
	}
}

function* watchOnLoadAllProductType() {
	yield takeEvery(eProductTypeActionTypeIds.GET_ALL_REQUEST, onLoadAllProductType);
}

function* watchOnLoadPaginationProductType() {
	yield takeEvery(eProductTypeActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationProductType);
}

function* watchOnSaveProductType() {
	yield takeEvery(eProductTypeActionTypeIds.SAVE_REQUEST, onSaveProductType);
}

function* watchOnDeleteProductType() {
	yield takeEvery(eProductTypeActionTypeIds.DELETE_REQUEST, onDeleteProductType);
}

function* productTypeSaga() {
	yield all([fork(watchOnLoadAllProductType)]);
	yield all([fork(watchOnLoadPaginationProductType)]);
	yield all([fork(watchOnSaveProductType)]);
	yield all([fork(watchOnDeleteProductType)]);
}

export default productTypeSaga;
