import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, ButtonLink, LoadingTable } from '../../components/base';
import { ConfirmModal } from '../../components/common/ConfirmModal';
import DxTable, { DxLookup, DxTableColumn } from '../../components/common/DxTable';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../components/container';
import { BaseShowDetail } from '../../context/base/BaseShowDetail';
import { eBaseActionStatus } from '../../context/base/eBaseActionStatus';
import { actions } from '../../context/stores/rootActions';
import { AppState } from '../../context/stores/rootReducers';
import { createUUID } from '../../utils/createUUID';
import BannerPositionDetail from './BannerPositionDetail';
import { BannerPositionModel } from '../../context/models/banners/BannerPositionModel';
import { BannerPositionActionTypes } from '../../context/stores/banner-position/IBannerPositionActionsTypes';

type IBannerPositionPageProps = {
	titlePage: string;
	status: eBaseActionStatus;
	allBannerPositions: BannerPositionModel[];
	selectedIds: number[];
	showDetail: BaseShowDetail<BannerPositionModel>;
	showConfirm: boolean;
	handleReloadAllData: () => void;
	handleSaveData: (data: BannerPositionModel) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	onShowDetail: (data: BaseShowDetail<BannerPositionModel>) => void;
	onShowConfirm: (isShow: boolean) => void;
};

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `Vị trí treo Banner`,
		status: state.bannerPosition.status,
		allBannerPositions: state.bannerPosition.allBannerPositions,
		selectedIds: state.bannerPosition.selectedIds,
		showDetail: state.bannerPosition.showDetail,
		showConfirm: state.bannerPosition.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<BannerPositionActionTypes>) => ({
	handleReloadAllData: () => {
		dispatch(actions.bannerPosition.getAllRequest());
	},
	handleSaveData: (data: BannerPositionModel) => {
		dispatch(actions.bannerPosition.saveRequest(data));
	},
	handleDeleteData: (ids: number[]) => {
		dispatch(actions.bannerPosition.deleteRequest(ids));
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.bannerPosition.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetail<BannerPositionModel>) => {
		dispatch(actions.bannerPosition.showDetail(data));
	},
	onShowConfirm: (isShow: boolean) => {
		dispatch(actions.bannerPosition.showConfirm(isShow));
	},
});

const BannerPositionPage = (props: IBannerPositionPageProps) => {
	const {
		titlePage,
		status,
		selectedIds,
		showConfirm,
		showDetail,
		allBannerPositions,
		handleReloadAllData,
		handleDeleteData,
		onChangeSelectedIds,
		handleSaveData,
		onShowDetail,
		onShowConfirm,
	} = props;

	useEffect(() => {
		handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const columns = useMemo((): any => {
		let result: any = [
			<DxTableColumn key={createUUID()} dataField='name' caption={`Tên vị trí`} minWidth={150}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='code' minWidth={150} caption={`Mã vị trí`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='width' minWidth={150} caption={`Chiều rộng`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='height' minWidth={150} caption={`Chiều cao`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='inActive' minWidth={80} caption={`Trạng thái`} falseText='Áp dụng' trueText='Không'></DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={50}
				caption={'Sửa'}
				alignment='center'
				allowExporting={true}
				cellRender={(cell: any) => {
					return (
						<ButtonLink onClick={() => onShowDetail({ isShow: true, detailData: cell.data })} title='Sửa' icon='ni ni-edit' theme='link' />
					);
				}}
			/>
		);
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<li>
								<Button
									text={`Xóa (${selectedIds.length})`}
									icon='icon ni ni-trash'
									className='d-md-inline-flex'
									theme='danger'
									isDisabled={selectedIds.length <= 0}
									onClick={() => onShowConfirm(true)}
								/>
							</li>

							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => onShowDetail({ isShow: true })}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={allBannerPositions}
								keyExpr='id'
								columns={columns}
								isLoading={status !== eBaseActionStatus.complete}
								defaultPageSize={50}
								filters={{
									refreshDataGrid: handleReloadAllData,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
			</PageContainer>
			{showDetail && showDetail.isShow && (	
				<>
					<BannerPositionDetail
						isSaving={status === eBaseActionStatus.saving}
						isShow={showDetail.isShow}
						detailModel={showDetail.detailData}
						onClose={() => onShowDetail({ isShow: false })}
						onSubmit={(data) => handleSaveData(data)}
						title={`${showDetail.detailData ? `Cập nhật` : `Thêm mới`} ${titlePage}`}
					/>
				</>
			)}
			{showConfirm && (
				<>
					<ConfirmModal
						show={showConfirm}
						innerText={`Bạn chắc chắn muốn xóa ${selectedIds.length} ${titlePage.toLowerCase()} đã chọn ?`}
						type='danger'
						onClose={() => onShowConfirm(false)}
						onConfirmed={() => handleDeleteData(selectedIds)}
						isLoading={status === eBaseActionStatus.loading || status === eBaseActionStatus.saving}
						btnConfirmText='Xóa'
						btnCloseText='Không xóa'
					/>
				</>
			)}
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerPositionPage);
