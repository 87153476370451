import { ApiBase } from "../../base/_ApiBase";
import { ApiClient } from "../../base/_ApiClient";
import { CollectionGroupModel } from "../../models/collections/CollectionGroupModel";
import { CollectionGroupRequest } from "../../models/collections/CollectionGroupRequest";

export const CollectionGroupApi = new class CollectionGroupApi extends ApiBase<CollectionGroupModel>{
    constructor({ baseUrl }: { baseUrl: string; }) {
        super(baseUrl);
    }
    GetColectionGroupByTypeAsync(data: CollectionGroupRequest) {
		return ApiClient.GET(`${this._baseUrl}/vi-tri?typeID=${data.typeID}`);
	}
}({ baseUrl: '/collections-group' });