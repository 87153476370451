import * as Yup from 'yup';
//Cần đổi tên tròn sql
export type CollectionModel = {
    id: number;
    collectionGroupId: number;
    name: string;
    slug: string;
    icon: string;
    description: string;
    image: string;
    link: string;
    stt: number;
}

export const dfCollectionModel: CollectionModel = {
    id: 0,
    collectionGroupId: 0,
    name: '',
    slug: '',
    icon: '',
    description: '',
    image: '',
    link: '',
    stt: 0,
}

export const validCollectionSchema = () => Yup.object().shape({
    collectionGroupId: Yup.number().required('Vui lòng nhập nhóm Bộ sưu tập.'),
    name: Yup.string().required('Vui lòng nhập Tên Bộ sưu tập.'),
    slug: Yup.string().required('Vui lòng nhập Link Bộ sưu tập.'),
});