import { NotifyHelper } from "../../../utils/NotifyHelper";
import { dfBaseShowDetail } from "../../base/BaseShowDetail";
import { BaseGetActionStatus, eBaseActionStatus } from "../../base/eBaseActionStatus";
import { ProductImageActionTypes, eProductImageActionTypeIds } from "./IProductImageActionsTypes";
import { IProductImageState } from "./IProductImageState";

const initialState: IProductImageState = {
    status: eBaseActionStatus.idle,
    allProductImages: [],
    selectedIds: [],
    showConfirm: false,
    showDetail: dfBaseShowDetail
}
const productImageReducer = (state: IProductImageState = initialState, action: ProductImageActionTypes): IProductImageState => {
    switch (action.type) {
        case eProductImageActionTypeIds.GET_ALL_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eProductImageActionTypeIds.GET_ALL_SUCCESS:
            return {
                ...state,
                allProductImages: action.payload,
                status: BaseGetActionStatus(action)
            }
        case eProductImageActionTypeIds.GET_ALL_FAILURE:
            return {
                ...state,
                allProductImages: [],
                status: BaseGetActionStatus(action)
            }
        case eProductImageActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                selectedIds: action.payload,
            }
        case eProductImageActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                showDetail: action.payload
            }
        case eProductImageActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                showConfirm: action.payload
            }
        case eProductImageActionTypeIds.SAVE_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eProductImageActionTypeIds.DELETE_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eProductImageActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success(action.payload || 'Cập nhật ảnh sản phẩm thành công.');
            return {
                ...state,
                showDetail: dfBaseShowDetail,
                status: BaseGetActionStatus(action),
            }
        case eProductImageActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success(action.payload.toString() || 'Xóa ảnh sản phẩm thành công');
            return {
                ...state,
                selectedIds: [],
                showConfirm: false,
                status: BaseGetActionStatus(action),
            }
        case eProductImageActionTypeIds.SAVE_FAILURE:
            NotifyHelper.Error(action.payload.toString());
            return {
                ...state,
                showDetail: dfBaseShowDetail,
                status: BaseGetActionStatus(action),
            }
        case eProductImageActionTypeIds.DELETE_FAILURE:
            NotifyHelper.Error(action.payload.toString());
            return {
                ...state,
                selectedIds: [],
                showConfirm: false,
                status: BaseGetActionStatus(action),
            }
        case eProductImageActionTypeIds.NEED_RELOAD:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        default:
            return state;
    }
}
export default productImageReducer;