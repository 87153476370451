import { all, call, put, fork, takeEvery } from "redux-saga/effects";
import { actions } from "../rootActions";
import { CollectionAction_DELETE_Request, CollectionAction_GETALL_Request, CollectionAction_GETPAGINATION_Request, CollectionAction_SAVE_Request, eCollectionActionTypeIds } from "./ICollectionActionsTypes";
import { BaseResponse } from "../../base/BaseResponse";
import { rootApi } from "../../api/rootApi";
import { CollectionModel } from "../../models/collections/CollectionModel";
import { CollectionResponse } from "../../models/collections/CollectionResponse";

const _sagaApi = rootApi.collection;

function* onLoadAllCollection(action: CollectionAction_GETALL_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetColectionByGroupAsync],action.payload);
        if (res.isSuccess && res.result) {
            const listCollection = res.result as CollectionResponse[];
            yield put(actions.collection.getAllSuccess(listCollection));
        }
        else {
            yield put(actions.collection.getAllFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.collection.getAllFailure(error || 'Có lỗi'));
    }
}

function* onLoadPaginationCollection(action: CollectionAction_GETPAGINATION_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetPaginationAsync], action.payload);
        if (res.isSuccess && res.result) {
            const listCollection = res.result as CollectionModel[];
            yield put(actions.collection.getPaginationSuccess({ listDatas: listCollection, pagination: res.pagination }));
        }
        else {
            yield put(actions.collection.getPaginationFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.collection.getPaginationFailure(error || 'Có lỗi'));
    }
}

function* onSaveCollection(action: CollectionAction_SAVE_Request) {
    try {
        if (action.payload.id > 0) {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.collection.saveSuccess(res.message));
                yield put(actions.collection.needReload());
            }
            else {
                yield put(actions.collection.saveFailure(res.message));
            }
        } else {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.collection.saveSuccess(res.message));
                yield put(actions.collection.needReload());
            }
            else {
                yield put(actions.collection.saveFailure(res.message));
            }
        }
    } catch (error: any) {
        yield put(actions.collection.saveFailure(error || 'Có lỗi'));
    }
}

function* onDeleteCollection(action: CollectionAction_DELETE_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
        if (res.isSuccess) {
            yield put(actions.collection.deleteSuccess(res.message));
            yield put(actions.collection.needReload());
        }
        else {
            yield put(actions.collection.deleteFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.collection.deleteFailure(error || 'Có lỗi'));
    }
}

function* watchOnLoadAllCollection() {
    yield takeEvery(eCollectionActionTypeIds.GET_ALL_REQUEST, onLoadAllCollection);
}

function* watchOnLoadPaginationCollection() {
    yield takeEvery(eCollectionActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationCollection);
}


function* watchOnSaveCollection() {
    yield takeEvery(eCollectionActionTypeIds.SAVE_REQUEST, onSaveCollection);
}

function* watchOnDeleteCollection() {
    yield takeEvery(eCollectionActionTypeIds.DELETE_REQUEST, onDeleteCollection);
}

function* collectionSaga() {
    yield all([fork(watchOnLoadAllCollection)]);
    yield all([fork(watchOnLoadPaginationCollection)]);
    yield all([fork(watchOnSaveCollection)]);
    yield all([fork(watchOnDeleteCollection)]);
}

export default collectionSaga;