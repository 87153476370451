import { CollectionTypeActionTypes, eCollectionTypeActionTypeIds } from "./ICollectionTypeActionsTypes"
import { BaseGetActionStatus, eBaseActionStatus } from "../../base/eBaseActionStatus"
import { NotifyHelper } from "../../../utils/NotifyHelper"
import { ICollectionTypeState } from "./ICollectionTypeState"
import { CollectionTypeModel } from "../../models/collections/CollectionTypeModel";
import { BasePaginationRespone, dfBasePagination } from "../../base/BasePaginationRespone";
import { dfBaseShowDetail } from "../../base/BaseShowDetail";

const initPaginationResponse: BasePaginationRespone<CollectionTypeModel> = { listDatas: [], pagination: dfBasePagination };
const initialState: ICollectionTypeState = {
    status: eBaseActionStatus.idle,
    allCollectionsType: [],
    selectedIds: [],
    showConfirm: false,
    paginationResponse: initPaginationResponse,
    showDetail: dfBaseShowDetail
}
const CollectionTypeReducer = (state: ICollectionTypeState = initialState, action: CollectionTypeActionTypes): ICollectionTypeState => {
    switch (action.type) {
        case eCollectionTypeActionTypeIds.GET_ALL_REQUEST:
        case eCollectionTypeActionTypeIds.GET_PAGINATION_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eCollectionTypeActionTypeIds.GET_ALL_SUCCESS:
            return {
                ...state,
                allCollectionsType: action.payload,
                status: BaseGetActionStatus(action)
            }
        case eCollectionTypeActionTypeIds.GET_PAGINATION_SUCCESS:
            return {
                ...state,
                paginationResponse: action.payload,
                status: BaseGetActionStatus(action)
            }
        case eCollectionTypeActionTypeIds.GET_ALL_FAILURE:
            return {
                ...state,
                allCollectionsType: [],
                status: BaseGetActionStatus(action)
            }
        case eCollectionTypeActionTypeIds.GET_PAGINATION_FAILURE:
            return {
                ...state,
                paginationResponse: initPaginationResponse,
                status: BaseGetActionStatus(action)
            }
        case eCollectionTypeActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                selectedIds: action.payload,
            }
        case eCollectionTypeActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                showDetail: action.payload
            }
        case eCollectionTypeActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                showConfirm: action.payload
            }
        case eCollectionTypeActionTypeIds.SAVE_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eCollectionTypeActionTypeIds.DELETE_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eCollectionTypeActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success(action.payload || 'Thêm chuyên mục thành công.');
            return {
                ...state,
                showDetail: dfBaseShowDetail,
                status: BaseGetActionStatus(action),
            }
        case eCollectionTypeActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success(action.payload.toString() || 'Xóa chuyên mục thành công');
            return {
                ...state,
                selectedIds: [],
                showConfirm: false,
                status: BaseGetActionStatus(action),
            }
        case eCollectionTypeActionTypeIds.SAVE_FAILURE:
            NotifyHelper.Error(action.payload.toString());
            return {
                ...state,
                showDetail: dfBaseShowDetail,
                status: BaseGetActionStatus(action),
            }
        case eCollectionTypeActionTypeIds.DELETE_FAILURE:
            NotifyHelper.Error(action.payload.toString());
            return {
                ...state,
                selectedIds: [],
                showConfirm: false,
                status: BaseGetActionStatus(action),
            }
        case eCollectionTypeActionTypeIds.NEED_RELOAD:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        default:
            return state;
    }
}
export default CollectionTypeReducer;