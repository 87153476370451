import { BasePaginationRespone } from '../../base/BasePaginationRespone';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { IActionTypeBase } from '../../base/IBaseActionTypes';
import { KeywordModel } from '../../models/keyword/KeywordModel';
import { KeywordRequest } from '../../models/keyword/KeywordRequest';
import { KeywordResponse } from '../../models/keyword/KeywordResponse';

export enum eKeywordActionTypeIds {
	GET_ALL_REQUEST = 'Keyword_GET_ALL_REQUEST',
	GET_ALL_SUCCESS = 'Keyword_GET_ALL_SUCCESS',
	GET_ALL_FAILURE = 'Keyword_GET_ALL_FAILURE',

	GET_PAGINATION_REQUEST = 'Keyword_GET_PAGINATION_REQUEST',
	GET_PAGINATION_SUCCESS = 'Keyword_GET_PAGINATION_SUCCESS',
	GET_PAGINATION_FAILURE = 'Keyword_GET_PAGINATION_FAILURE',

	SAVE_REQUEST = 'Keyword_SAVE_REQUEST',
	SAVE_SUCCESS = 'Keyword_SAVE_SUCCESS',
	SAVE_FAILURE = 'Keyword_SAVE_FAILURE',

	DELETE_REQUEST = 'Keyword_DELETE_REQUEST',
	DELETE_SUCCESS = 'Keyword_DELETE_SUCCESS',
	DELETE_FAILURE = 'Keyword_DELETE_FAILURE',

	NEED_RELOAD = 'Keyword_NEED_RELOAD',

	CHANGE_SELECTED_IDS = 'Keyword_CHANGE_SELECTED_IDS',
	SHOW_DETAIL = 'Keyword_SHOW_DETAIL',
	SHOW_CONFIRM = 'Keyword_SHOW_CONFIRM',
}

export interface KeywordAction_GETALL_Request extends IActionTypeBase<eKeywordActionTypeIds.GET_ALL_REQUEST, KeywordRequest> {}
export interface KeywordAction_GETALL_Success extends IActionTypeBase<eKeywordActionTypeIds.GET_ALL_SUCCESS, KeywordResponse[]> {}
export interface KeywordAction_GETALL_Failure extends IActionTypeBase<eKeywordActionTypeIds.GET_ALL_FAILURE, Error | string> {}

export interface KeywordAction_GETPAGINATION_Request
	extends IActionTypeBase<eKeywordActionTypeIds.GET_PAGINATION_REQUEST, BaseSelectRequest> {}
export interface KeywordAction_GETPAGINATION_Success
	extends IActionTypeBase<eKeywordActionTypeIds.GET_PAGINATION_SUCCESS, BasePaginationRespone<KeywordModel>> {}
export interface KeywordAction_GETPAGINATION_Failure
	extends IActionTypeBase<eKeywordActionTypeIds.GET_PAGINATION_FAILURE, Error | string> {}

export interface KeywordAction_SAVE_Request extends IActionTypeBase<eKeywordActionTypeIds.SAVE_REQUEST, KeywordModel> {}
export interface KeywordAction_SAVE_Success extends IActionTypeBase<eKeywordActionTypeIds.SAVE_SUCCESS, string> {}
export interface KeywordAction_SAVE_Failure extends IActionTypeBase<eKeywordActionTypeIds.SAVE_FAILURE, Error | string> {}

export interface KeywordAction_DELETE_Request extends IActionTypeBase<eKeywordActionTypeIds.DELETE_REQUEST, number[]> {}
export interface KeywordAction_DELETE_Success extends IActionTypeBase<eKeywordActionTypeIds.DELETE_SUCCESS, string> {}
export interface KeywordAction_DELETE_Failure extends IActionTypeBase<eKeywordActionTypeIds.DELETE_FAILURE, Error | string> {}

export interface KeywordAction_RELOAD extends IActionTypeBase<eKeywordActionTypeIds.NEED_RELOAD, undefined> {}

export interface KeywordAction_SHOW_DETAIL extends IActionTypeBase<eKeywordActionTypeIds.SHOW_DETAIL, BaseShowDetail<KeywordModel>> {}
export interface KeywordAction_CHANGE_SELECTED_IDS extends IActionTypeBase<eKeywordActionTypeIds.CHANGE_SELECTED_IDS, number[]> {}
export interface KeywordAction_SHOW_CONFIRM extends IActionTypeBase<eKeywordActionTypeIds.SHOW_CONFIRM, boolean> {}

export type KeywordActionTypes =
	| KeywordAction_GETALL_Request
	| KeywordAction_GETALL_Success
	| KeywordAction_GETALL_Failure
	| KeywordAction_GETPAGINATION_Request
	| KeywordAction_GETPAGINATION_Success
	| KeywordAction_GETPAGINATION_Failure
	| KeywordAction_SAVE_Request
	| KeywordAction_SAVE_Success
	| KeywordAction_SAVE_Failure
	| KeywordAction_DELETE_Request
	| KeywordAction_DELETE_Success
	| KeywordAction_DELETE_Failure
	| KeywordAction_RELOAD
	| KeywordAction_SHOW_DETAIL
	| KeywordAction_CHANGE_SELECTED_IDS
	| KeywordAction_SHOW_CONFIRM;
