import { BaseShowDetail } from '../../base/BaseShowDetail';
import { BaseAction } from '../../base/IBaseActionRespone';
import { BannerConfigModel } from '../../models/banners/BannerConfigModel';
import {
	BannerConfigAction_CHANGE_SELECTED_IDS,
	BannerConfigAction_GETALL_Failure,
	BannerConfigAction_GETALL_Request,
	BannerConfigAction_GETALL_Success,
	BannerConfigAction_RELOAD,
	BannerConfigAction_SAVE_Failure,
	BannerConfigAction_SAVE_Request,
	BannerConfigAction_SAVE_Success,
	BannerConfigAction_SHOW_CONFIRM,
	BannerConfigAction_SHOW_DETAIL,
	eBannerConfigActionTypeIds,
} from './IBannerConfigActionsTypes';

export const BannerConfigActions = {
	//ALL
	getAllRequest: (BannerId: number): BannerConfigAction_GETALL_Request =>
		BaseAction(eBannerConfigActionTypeIds.GET_ALL_REQUEST, BannerId),
	getAllSuccess: (data: BannerConfigModel[]): BannerConfigAction_GETALL_Success =>
		BaseAction(eBannerConfigActionTypeIds.GET_ALL_SUCCESS, data),
	getAllFailure: (error: Error | string): BannerConfigAction_GETALL_Failure =>
		BaseAction(eBannerConfigActionTypeIds.GET_ALL_FAILURE, error),
	//SAVE
    saveRequest: (entity: BannerConfigModel): BannerConfigAction_SAVE_Request => BaseAction(eBannerConfigActionTypeIds.SAVE_REQUEST, entity),
    saveSuccess: (message: string): BannerConfigAction_SAVE_Success => BaseAction(eBannerConfigActionTypeIds.SAVE_SUCCESS, message),
    saveFailure: (error: Error | string): BannerConfigAction_SAVE_Failure => BaseAction(eBannerConfigActionTypeIds.SAVE_FAILURE, error),
	//RELOAD
	needReload: (): BannerConfigAction_RELOAD => BaseAction(eBannerConfigActionTypeIds.NEED_RELOAD, undefined),
	//ACTIONs
	changeSelectedIds: (ids: number[]): BannerConfigAction_CHANGE_SELECTED_IDS =>
		BaseAction(eBannerConfigActionTypeIds.CHANGE_SELECTED_IDS, ids),
	showDetail: (detail: BaseShowDetail<BannerConfigModel>): BannerConfigAction_SHOW_DETAIL =>
		BaseAction(eBannerConfigActionTypeIds.SHOW_DETAIL, detail),
	showConfirm: (show: boolean): BannerConfigAction_SHOW_CONFIRM => BaseAction(eBannerConfigActionTypeIds.SHOW_CONFIRM, show),
};
