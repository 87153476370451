import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { BaseForm, DxDateBox, LoadingPanel, NumberBox, TextAreaBox, TextBox } from '../../../components/base';
import { ImageBox } from '../../../components/base/ImageBox';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import ComboBoxProductCategory from '../../../components/shared/hoc-product-category/ComboBoxProductCategory';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { ProductActionConfirmRequest } from '../../../context/models/products/ProductActionConfirmRequest';
import { ProductModel, dfProductModel, validProductSchema } from '../../../context/models/products/ProductModel';
import { eProductStatus, getProductStatusName } from '../../../context/models/products/eProductStatus';
import { ProductActionTypes } from '../../../context/stores/products/IProductActionsTypes';
import { actions } from '../../../context/stores/rootActions';
import { AppState } from '../../../context/stores/rootReducers';
import { slugHelper } from '../../../utils/slugHelper';
import { ProductButton } from '../button-actions/ProductButton';
import { ChangeStatusConfirm } from '../change-status-confirm';
import { history } from '../../../utils/history';
import TextEditor from '../../../components/base/TextEditor';
import ProductImageTable from '../product-images';

const mapStateToProps = (state: AppState) => {
	return {
		status: state.product.status,
		detailResponse: state.product.detailResponse,
		selectedIds: state.product.selectedIds,
		showConfirm: state.product.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<ProductActionTypes>) => ({
	handleGetDetail: (id: number) => {
		dispatch(actions.product.detailRequest(id));
	},
	handleSaveData: (data: ProductModel) => {
		dispatch(actions.product.saveRequest(data));
	},
	handleChangeStatus: (detail: ProductActionConfirmRequest) => {
		dispatch(
			actions.product.changeStatusRequest({
				actionStatus: detail.actionStatus,
				productIds: detail.ids || [],
				receivedBy: detail.receivedBy || '',
				message: detail.message || '',
				subject: detail.subject || '',
			})
		);
	},
	onShowConfirm: (detail: ProductActionConfirmRequest) => {
		dispatch(actions.product.showConfirm(detail));
	},
});

type IProductEditPageProps = {
	status: eBaseActionStatus;
	detailResponse?: ProductModel;
	selectedIds: number[];
	showConfirm: ProductActionConfirmRequest;
	handleGetDetail: (id: number) => void;
	handleSaveData: (data: ProductModel) => void;
	handleChangeStatus: (data: ProductActionConfirmRequest) => void;
	onShowConfirm: (data: ProductActionConfirmRequest) => void;
};

const ProductEditPage = (props: IProductEditPageProps) => {
	const { id } = useParams();
	const productId = useMemo(() => parseInt(id || '0'), [id]);
	const navigate = useNavigate();

	const { detailResponse, status, showConfirm, selectedIds, handleGetDetail, handleChangeStatus, handleSaveData, onShowConfirm } = props;

	const [initialValues, setInitialValues] = useState<ProductModel>(dfProductModel);
	const {
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<ProductModel>({
		resolver: yupResolver(validProductSchema()),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: ProductModel) => {
		handleSaveData({ ...values });
	};

	const watchChangeName = useWatch({ control, name: 'name', defaultValue: '' });

	useEffect(() => {
		if (initialValues.status !== eProductStatus.PUBLISHED) {
			const slug = slugHelper.toSlug(watchChangeName);
			setValue('alias', slug);
			setInitialValues((prev) => ({ ...prev, alias: slug }));
		}
	}, [watchChangeName, setValue, initialValues]);

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues]);

	useEffect(() => {
		if (detailResponse) {
			setInitialValues((prev) => ({
				...prev,
				...detailResponse,
				publishedOnDate: moment(detailResponse.publishedOnDate).format(),
				actionStatus: eProductStatus.EDITED,
			}));
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailResponse]);

	useEffect(() => {
		if (productId > 0) {
			handleGetDetail(productId);
		} else {
			setInitialValues(dfProductModel);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productId]);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) navigate(history.location?.pathname || '/', { replace: true });
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);


	return (
		<>
			<PageContainer>
				<PageHeader title={`Thông tin sản phẩm`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<ProductButton
								isOwner={true}
								productStatus={initialValues.status}
								isActionDisable={true}
								onActionConfirm={(actionChange) => {
									onShowConfirm({ isShow: true, actionStatus: actionChange, ids: [] });
									setInitialValues((prev) => ({ ...prev, actionStatus: actionChange }));
								}}
								handleSubmit={handleSubmit(onSubmitHandler)}
								isSaving={status === eBaseActionStatus.saving}
							/>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingPanel />}
						<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
							<div className='row g-4'>
								<div className='col-xl-9 col-sm-12'>
									<div className='nk-block'>
										<div className='row g-4'>
											<div className='col-lg-8'>
												<div className='nk-block-head nk-block-head-sm nk-block-between'>
													<h6 className='name'>Tên sản phẩm</h6>
												</div>
												<TextAreaBox
													name='name'
													value={initialValues.name}
													error={errors.name?.message}
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, name: value }));
													}}
													className='form-control-simple no-resize editor-focus editor-f-22'
													classWrapper='border-bottom'
													sizeClass='sm'
													placeholder='Viết gì đó...'
												/>
												<TextBox
													name='alias'
													value={initialValues.alias}
													error={errors.alias?.message}
													isDisabled={initialValues.status === eProductStatus.PUBLISHED}
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, alias: value }));
													}}
													label='Slug'
													sizeClass='sm'
													className='form-control-simple editor-focus  editor-f-16'
													classWrapper='border-bottom'
													helpBlock='Link hiển thị: ten-san-pham'
												/>
											</div>
											<div className='col-lg-4'>
												<div className='nk-block-head nk-block-head-sm nk-block-between'>
													<h6 className='titleSlug'>Ảnh bìa</h6>
												</div>
												<ImageBox
													name='image'
													value={initialValues.image}
													isDisabled={initialValues.name.length <= 0}
													file_name={initialValues.name}
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, image: value || '' }));
													}}
												/>
											</div>
										</div>
									</div>
									<div className='nk-block'>
										<div className='row g-4'>
											<div className='col-lg-3'>
												<div className='nk-block-head nk-block-head-sm nk-block-between'>
													<h6 className='sku'>SKU</h6>
												</div>
												<TextBox
													name='sku'
													value={initialValues.sku}
													error={errors.sku?.message}
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, sku: value }));
													}}
													sizeClass='sm'
													helpBlock='Mã sản phẩm'
												/>
											</div>
											<div className='col-lg-3'>
												<div className='nk-block-head nk-block-head-sm nk-block-between'>
													<h6 className='price'>Giá niêm yết</h6>
												</div>
												<NumberBox
													name='price'
													value={initialValues.price}
													error={errors.price?.message}
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, price: value }));
													}}
													sizeClass='sm'
													helpBlock='Giá niêm yết của sản phẩm'
												/>
											</div>
											<div className='col-lg-3'>
												<div className='nk-block-head nk-block-head-sm nk-block-between'>
													<h6 className='priceOnSale'>Giá bán</h6>
												</div>
												<NumberBox
													name='priceOnSale'
													value={initialValues.priceOnSale}
													error={errors.priceOnSale?.message}
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, priceOnSale: value }));
													}}
													sizeClass='sm'
													helpBlock='Giá bán của sản phẩm'
												/>
											</div>
											<div className='col-lg-3'>
												<div className='nk-block-head nk-block-head-sm nk-block-between'>
													<h6 className='catId'>Danh mục</h6>
												</div>
												<ComboBoxProductCategory
													name='catId'
													value={initialValues.catId}
													error={errors.catId?.message}
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, catId: value }));
													}}
												/>
											</div>
										</div>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='initContent'>Mô tả ngắn</h6>
										</div>
										<TextAreaBox
											name='initContent'
											value={initialValues.initContent}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, initContent: value }));
											}}
											className='form-control-simple no-resize editor-focus editor-f-18'
											classWrapper='border-bottom'
											sizeClass='sm'
											placeholder='Viết gì đó...'
										/>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='summary'>Đặc tả</h6>
										</div>
										<TextEditor
											name='summary'
											value={initialValues.summary}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, summary: value }));
											}}
										/>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='contentHtml'>Giới thiệu</h6>
										</div>
										<TextEditor
											name='contentHtml'
											value={initialValues.contentHtml}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, contentHtml: value }));
											}}
										/>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='contentHtml'>Thư viện ảnh</h6>
										</div>
										<ProductImageTable
											productId={productId}
											title={detailResponse?.name || ''}
											description={detailResponse?.initContent || ''}
										/>
									</div>
									{/* <div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='relatedIds'>Tin liên quan</h6>
										</div>
										<RelatedProducts />
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='relatedProductIds'>Sản phẩm liên quan</h6>
										</div>
										<RelatedProducts />
									</div> */}
								</div>
								<div className='col-xl-3'>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='metaTitle'>Ngày đăng bán</h6>
										</div>
										<DxDateBox
											name='publishedOnDate'
											value={moment(initialValues.publishedOnDate).format()}
											displayFormat={'dd/MM/yyyy HH:mm'}
											typeDate='datetime'
											onValueChanged={(e) => {
												setInitialValues((prev) => ({ ...prev, publishedOnDate: e.value }));
											}}
										/>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='metaTitle'>Meta Tile</h6>
										</div>
										<TextBox
											name='metaTitle'
											value={initialValues.metaTitle}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, metaTitle: value }));
											}}
											sizeClass='sm'
											className='form-control-simple editor-focus  editor-f-18'
											classWrapper='border-bottom'
										/>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='metaDescription'>Meta Description</h6>
										</div>
										<TextAreaBox
											name='metaDescription'
											value={initialValues.metaDescription}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, metaDescription: value }));
											}}
											sizeClass='sm'
											className='form-control-simple editor-focus  editor-f-18'
											classWrapper='border-bottom'
										/>
									</div>
								</div>
							</div>
						</BaseForm>
					</BlockCard>
				</PageBody>
			</PageContainer>
			{showConfirm && showConfirm.isShow && (
				<>
					<ChangeStatusConfirm
						isShow={showConfirm.isShow}
						onClose={() => onShowConfirm({ isShow: false, actionStatus: showConfirm.actionStatus, ids: [] })}
						onConfirmStatus={handleSubmit(onSubmitHandler)}
						isLoading={status === eBaseActionStatus.loading}
						mesageText={`Bạn chắc chắc muôn ${getProductStatusName(showConfirm.actionStatus)} sản phẩm này?`}
						actionStatus={showConfirm.actionStatus}
						selectedIds={selectedIds}
					/>
				</>
			)}
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductEditPage);
