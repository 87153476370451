import { all, call, put, fork, takeEvery } from "redux-saga/effects";
import { actions } from "../rootActions";
import { CollectionTypeAction_DELETE_Request, CollectionTypeAction_GETALL_Request, CollectionTypeAction_GETPAGINATION_Request, CollectionTypeAction_SAVE_Request, eCollectionTypeActionTypeIds } from "./ICollectionTypeActionsTypes";
import { BaseResponse } from "../../base/BaseResponse";
import { rootApi } from "../../api/rootApi";
import { CollectionTypeModel } from "../../models/collections/CollectionTypeModel";

const _sagaApi = rootApi.collectionType;

function* onLoadAllCollectionType(action: CollectionTypeAction_GETALL_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetAllAsync]);
        if (res.isSuccess && res.result) {
            const listCollection = res.result as CollectionTypeModel[];
            yield put(actions.collectionType.getAllSuccess(listCollection));
        }
        else {
            yield put(actions.collectionType.getAllFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.collectionType.getAllFailure(error || 'Có lỗi'));
    }
}

function* onLoadPaginationCollectionType(action: CollectionTypeAction_GETPAGINATION_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetPaginationAsync], action.payload);
        if (res.isSuccess && res.result) {
            const listCollection = res.result as CollectionTypeModel[];
            yield put(actions.collectionType.getPaginationSuccess({ listDatas: listCollection, pagination: res.pagination }));
        }
        else {
            yield put(actions.collectionType.getPaginationFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.collectionType.getPaginationFailure(error || 'Có lỗi'));
    }
}

function* onSaveCollectionType(action: CollectionTypeAction_SAVE_Request) {
    try {
        if (action.payload.id > 0) {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.collectionType.saveSuccess(res.message));
                yield put(actions.collectionType.needReload());
            }
            else {
                yield put(actions.collectionType.saveFailure(res.message));
            }
        } else {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.collectionType.saveSuccess(res.message));
                yield put(actions.collectionType.needReload());
            }
            else {
                yield put(actions.collectionType.saveFailure(res.message));
            }
        }
    } catch (error: any) {
        yield put(actions.collectionType.saveFailure(error || 'Có lỗi'));
    }
}

function* onDeleteCollectionType(action: CollectionTypeAction_DELETE_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
        if (res.isSuccess) {
            yield put(actions.collectionType.deleteSuccess(res.message));
            yield put(actions.collectionType.needReload());
        }
        else {
            yield put(actions.collectionType.deleteFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.collectionType.deleteFailure(error || 'Có lỗi'));
    }
}

function* watchOnLoadAllCollectionType() {
    yield takeEvery(eCollectionTypeActionTypeIds.GET_ALL_REQUEST, onLoadAllCollectionType);
}

function* watchOnLoadPaginationCollectionType() {
    yield takeEvery(eCollectionTypeActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationCollectionType);
}


function* watchOnSaveCollectionType() {
    yield takeEvery(eCollectionTypeActionTypeIds.SAVE_REQUEST, onSaveCollectionType);
}

function* watchOnDeleteCollectionType() {
    yield takeEvery(eCollectionTypeActionTypeIds.DELETE_REQUEST, onDeleteCollectionType);
}

function* collectionTypeSaga() {
    yield all([fork(watchOnLoadAllCollectionType)]);
    yield all([fork(watchOnLoadPaginationCollectionType)]);
    yield all([fork(watchOnSaveCollectionType)]);
    yield all([fork(watchOnDeleteCollectionType)]);
}

export default collectionTypeSaga;