import { CollectionModel } from "../../models/collections/CollectionModel";
import { ApiBase } from "../../base/_ApiBase";
import { CollectionRequest } from "../../models/collections/CollectionRequest";
import { ApiClient } from "../../base/_ApiClient";

export const CollectionApi = new class CollectionApi extends ApiBase<CollectionModel>{
    constructor({ baseUrl }: { baseUrl: string; }) {
        super(baseUrl);
    }
    GetColectionByGroupAsync(data: CollectionRequest) {
		return ApiClient.GET(`${this._baseUrl}/nhom-bo-suu-tap?groupID=${data.groupID}`);
	}
}({ baseUrl: '/collections' });