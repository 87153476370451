import { BasePaginationRespone } from '../../base/BasePaginationRespone';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { IActionTypeBase } from '../../base/IBaseActionTypes';
import { ProductTypeModel } from '../../models/product-type/ProductTypeModel';

export enum eProductTypeActionTypeIds {
	GET_ALL_REQUEST = 'ProductType_GET_ALL_REQUEST',
	GET_ALL_SUCCESS = 'ProductType_GET_ALL_SUCCESS',
	GET_ALL_FAILURE = 'ProductType_GET_ALL_FAILURE',

	GET_PAGINATION_REQUEST = 'ProductType_GET_PAGINATION_REQUEST',
	GET_PAGINATION_SUCCESS = 'ProductType_GET_PAGINATION_SUCCESS',
	GET_PAGINATION_FAILURE = 'ProductType_GET_PAGINATION_FAILURE',

	GET_LISTPRODUCT_REQUEST = 'ProductType_GET_LISTPRODUCT_REQUEST',
	GET_LISTPRODUCT_SUCCESS = 'ProductType_GET_LISTPRODUCT_SUCCESS',
	GET_LISTPRODUCT_FAILURE = 'ProductType_GET_LISTPRODUCT_FAILURE',

	SAVE_REQUEST = 'ProductType_SAVE_REQUEST',
	SAVE_SUCCESS = 'ProductType_SAVE_SUCCESS',
	SAVE_FAILURE = 'ProductType_SAVE_FAILURE',

	DELETE_REQUEST = 'ProductType_DELETE_REQUEST',
	DELETE_SUCCESS = 'ProductType_DELETE_SUCCESS',
	DELETE_FAILURE = 'ProductType_DELETE_FAILURE',

	NEED_RELOAD = 'ProductType_NEED_RELOAD',

	CHANGE_SELECTED_IDS = 'ProductType_CHANGE_SELECTED_IDS',
	SHOW_DETAIL = 'ProductType_SHOW_DETAIL',
	SHOW_CONFIRM = 'ProductType_SHOW_CONFIRM',
}

export interface ProductTypeAction_GETALL_Request extends IActionTypeBase<eProductTypeActionTypeIds.GET_ALL_REQUEST, undefined> {}
export interface ProductTypeAction_GETALL_Success extends IActionTypeBase<eProductTypeActionTypeIds.GET_ALL_SUCCESS, ProductTypeModel[]> {}
export interface ProductTypeAction_GETALL_Failure extends IActionTypeBase<eProductTypeActionTypeIds.GET_ALL_FAILURE, Error | string> {}

export interface ProductTypeAction_GETPAGINATION_Request
	extends IActionTypeBase<eProductTypeActionTypeIds.GET_PAGINATION_REQUEST, BaseSelectRequest> {}
export interface ProductTypeAction_GETPAGINATION_Success
	extends IActionTypeBase<eProductTypeActionTypeIds.GET_PAGINATION_SUCCESS, BasePaginationRespone<ProductTypeModel>> {}
export interface ProductTypeAction_GETPAGINATION_Failure
	extends IActionTypeBase<eProductTypeActionTypeIds.GET_PAGINATION_FAILURE, Error | string> {}

export interface ProductTypeAction_SAVE_Request extends IActionTypeBase<eProductTypeActionTypeIds.SAVE_REQUEST, ProductTypeModel> {}
export interface ProductTypeAction_SAVE_Success extends IActionTypeBase<eProductTypeActionTypeIds.SAVE_SUCCESS, string> {}
export interface ProductTypeAction_SAVE_Failure extends IActionTypeBase<eProductTypeActionTypeIds.SAVE_FAILURE, Error | string> {}

export interface ProductTypeAction_DELETE_Request extends IActionTypeBase<eProductTypeActionTypeIds.DELETE_REQUEST, number[]> {}
export interface ProductTypeAction_DELETE_Success extends IActionTypeBase<eProductTypeActionTypeIds.DELETE_SUCCESS, string> {}
export interface ProductTypeAction_DELETE_Failure extends IActionTypeBase<eProductTypeActionTypeIds.DELETE_FAILURE, Error | string> {}

export interface ProductTypeAction_RELOAD extends IActionTypeBase<eProductTypeActionTypeIds.NEED_RELOAD, undefined> {}

export interface ProductTypeAction_SHOW_DETAIL
	extends IActionTypeBase<eProductTypeActionTypeIds.SHOW_DETAIL, BaseShowDetail<ProductTypeModel>> {}
export interface ProductTypeAction_CHANGE_SELECTED_IDS extends IActionTypeBase<eProductTypeActionTypeIds.CHANGE_SELECTED_IDS, number[]> {}
export interface ProductTypeAction_SHOW_CONFIRM extends IActionTypeBase<eProductTypeActionTypeIds.SHOW_CONFIRM, boolean> {}

export type ProductTypeActionTypes =
	| ProductTypeAction_GETALL_Request
	| ProductTypeAction_GETALL_Success
	| ProductTypeAction_GETALL_Failure
	| ProductTypeAction_GETPAGINATION_Request
	| ProductTypeAction_GETPAGINATION_Success
	| ProductTypeAction_GETPAGINATION_Failure
	| ProductTypeAction_SAVE_Request
	| ProductTypeAction_SAVE_Success
	| ProductTypeAction_SAVE_Failure
	| ProductTypeAction_DELETE_Request
	| ProductTypeAction_DELETE_Success
	| ProductTypeAction_DELETE_Failure
	| ProductTypeAction_RELOAD
	| ProductTypeAction_SHOW_DETAIL
	| ProductTypeAction_CHANGE_SELECTED_IDS
	| ProductTypeAction_SHOW_CONFIRM;
