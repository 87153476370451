import { ProductTypeActionTypes, eProductTypeActionTypeIds } from './IProductTypeActionsTypes';
import { BaseGetActionStatus, eBaseActionStatus } from '../../base/eBaseActionStatus';
import { NotifyHelper } from '../../../utils/NotifyHelper';
import { IProductTypeState } from './IProductTypeState';
import { BasePaginationRespone, dfBasePagination } from '../../base/BasePaginationRespone';
import { dfBaseShowDetail } from '../../base/BaseShowDetail';
import { ProductTypeModel } from '../../models/product-type/ProductTypeModel';

const initPaginationResponse: BasePaginationRespone<ProductTypeModel> = { listDatas: [], pagination: dfBasePagination };
const initialState: IProductTypeState = {
	status: eBaseActionStatus.idle,
	allProductTypes: [],
	selectedIds: [],
	showConfirm: false,
	paginationResponse: initPaginationResponse,
	showDetail: dfBaseShowDetail,
};
const productTypeReducer = (state: IProductTypeState = initialState, action: ProductTypeActionTypes): IProductTypeState => {
	switch (action.type) {
		case eProductTypeActionTypeIds.GET_ALL_REQUEST:
		case eProductTypeActionTypeIds.GET_PAGINATION_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeActionTypeIds.GET_ALL_SUCCESS:
			return {
				...state,
				allProductTypes: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeActionTypeIds.GET_PAGINATION_SUCCESS:
			return {
				...state,
				paginationResponse: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeActionTypeIds.GET_ALL_FAILURE:
			return {
				...state,
				allProductTypes: [],
				status: BaseGetActionStatus(action),
			};
		case eProductTypeActionTypeIds.GET_PAGINATION_FAILURE:
			return {
				...state,
				paginationResponse: initPaginationResponse,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeActionTypeIds.CHANGE_SELECTED_IDS:
			return {
				...state,
				selectedIds: action.payload,
			};
		case eProductTypeActionTypeIds.SHOW_DETAIL:
			return {
				...state,
				showDetail: action.payload,
			};
		case eProductTypeActionTypeIds.SHOW_CONFIRM:
			return {
				...state,
				showConfirm: action.payload,
			};
		case eProductTypeActionTypeIds.SAVE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeActionTypeIds.DELETE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeActionTypeIds.SAVE_SUCCESS:
			NotifyHelper.Success(action.payload || 'Thêm chuyên mục thành công.');
			return {
				...state,
				showDetail: dfBaseShowDetail,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeActionTypeIds.DELETE_SUCCESS:
			NotifyHelper.Success(action.payload.toString() || 'Xóa chuyên mục thành công');
			return {
				...state,
				selectedIds: [],
				showConfirm: false,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeActionTypeIds.SAVE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				showDetail: dfBaseShowDetail,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeActionTypeIds.DELETE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				selectedIds: [],
				showConfirm: false,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeActionTypeIds.NEED_RELOAD:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		default:
			return state;
	}
};
export default productTypeReducer;
