import { useMemo } from 'react';
import { DxSelectBox } from '../../../base/DxSelectBox';
import { useLookupCollection } from '../useLookupCollection';

type IComboBoxCollectionProps = {
	name: string;
	value: any;
	valueExpr?: string;
	displayExpr?: string;
	onValueChanged: (value: any) => void;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	hasValid?: boolean;
	placeholder?: string;
};

const ComboBoxCollection = (props: IComboBoxCollectionProps) => {
	const { collection_all } = useLookupCollection();
	const dataSource = useMemo(() => {
		return collection_all?.map((item) => ({ id: item.id, name: item.name }));
	}, [collection_all]);
	return (
		<DxSelectBox
			dataSource={dataSource}
			valueExpr={'id'}
			displayExpr={'name'}
			{...props}
			placeholder={props.placeholder || 'Chọn danh mục'}
			isClearable={true}
		/>
	);
};
export default ComboBoxCollection;
