import { BasePaginationRespone } from "../../base/BasePaginationRespone";
import { BaseSelectRequest } from "../../base/BaseSelectRequest";
import { BaseShowDetail } from "../../base/BaseShowDetail";
import { IActionTypeBase } from "../../base/IBaseActionTypes";
import { CollectionTypeModel } from "../../models/collections/CollectionTypeModel";

export enum eCollectionTypeActionTypeIds {
    GET_ALL_REQUEST = "CollectionType_GET_ALL_REQUEST",
    GET_ALL_SUCCESS = "CollectionType_GET_ALL_SUCCESS",
    GET_ALL_FAILURE = "CollectionType_GET_ALL_FAILURE",

    GET_PAGINATION_REQUEST = "CollectionType_GET_PAGINATION_REQUEST",
    GET_PAGINATION_SUCCESS = "CollectionType_GET_PAGINATION_SUCCESS",
    GET_PAGINATION_FAILURE = "CollectionType_GET_PAGINATION_FAILURE",

    SAVE_REQUEST = "CollectionType_SAVE_REQUEST",
    SAVE_SUCCESS = "CollectionType_SAVE_SUCCESS",
    SAVE_FAILURE = "CollectionType_SAVE_FAILURE",

    DELETE_REQUEST = "CollectionType_DELETE_REQUEST",
    DELETE_SUCCESS = "CollectionType_DELETE_SUCCESS",
    DELETE_FAILURE = "CollectionType_DELETE_FAILURE",

    NEED_RELOAD = "CollectionType_NEED_RELOAD",

    CHANGE_SELECTED_IDS = "CollectionType_CHANGE_SELECTED_IDS",
    SHOW_DETAIL = "CollectionType_SHOW_DETAIL",
    SHOW_CONFIRM = "CollectionType_SHOW_CONFIRM",
}

export interface CollectionTypeAction_GETALL_Request extends IActionTypeBase<eCollectionTypeActionTypeIds.GET_ALL_REQUEST, undefined> { }
export interface CollectionTypeAction_GETALL_Success extends IActionTypeBase<eCollectionTypeActionTypeIds.GET_ALL_SUCCESS, CollectionTypeModel[]> { }
export interface CollectionTypeAction_GETALL_Failure extends IActionTypeBase<eCollectionTypeActionTypeIds.GET_ALL_FAILURE, Error | string> { }

export interface CollectionTypeAction_GETPAGINATION_Request extends IActionTypeBase<eCollectionTypeActionTypeIds.GET_PAGINATION_REQUEST, BaseSelectRequest> { }
export interface CollectionTypeAction_GETPAGINATION_Success extends IActionTypeBase<eCollectionTypeActionTypeIds.GET_PAGINATION_SUCCESS, BasePaginationRespone<CollectionTypeModel>> { }
export interface CollectionTypeAction_GETPAGINATION_Failure extends IActionTypeBase<eCollectionTypeActionTypeIds.GET_PAGINATION_FAILURE, Error | string> { }

export interface CollectionTypeAction_SAVE_Request extends IActionTypeBase<eCollectionTypeActionTypeIds.SAVE_REQUEST, CollectionTypeModel> { }
export interface CollectionTypeAction_SAVE_Success extends IActionTypeBase<eCollectionTypeActionTypeIds.SAVE_SUCCESS, string> { }
export interface CollectionTypeAction_SAVE_Failure extends IActionTypeBase<eCollectionTypeActionTypeIds.SAVE_FAILURE, Error | string> { }

export interface CollectionTypeAction_DELETE_Request extends IActionTypeBase<eCollectionTypeActionTypeIds.DELETE_REQUEST, number[]> { }
export interface CollectionTypeAction_DELETE_Success extends IActionTypeBase<eCollectionTypeActionTypeIds.DELETE_SUCCESS, string> { }
export interface CollectionTypeAction_DELETE_Failure extends IActionTypeBase<eCollectionTypeActionTypeIds.DELETE_FAILURE, Error | string> { }

export interface CollectionTypeAction_RELOAD extends IActionTypeBase<eCollectionTypeActionTypeIds.NEED_RELOAD, undefined> { }

export interface CollectionTypeAction_SHOW_DETAIL extends IActionTypeBase<eCollectionTypeActionTypeIds.SHOW_DETAIL, BaseShowDetail<CollectionTypeModel>> { }
export interface CollectionTypeAction_CHANGE_SELECTED_IDS extends IActionTypeBase<eCollectionTypeActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }
export interface CollectionTypeAction_SHOW_CONFIRM extends IActionTypeBase<eCollectionTypeActionTypeIds.SHOW_CONFIRM, boolean> { }

export type CollectionTypeActionTypes = CollectionTypeAction_GETALL_Request | CollectionTypeAction_GETALL_Success | CollectionTypeAction_GETALL_Failure |
    CollectionTypeAction_GETPAGINATION_Request | CollectionTypeAction_GETPAGINATION_Success | CollectionTypeAction_GETPAGINATION_Failure |
    CollectionTypeAction_SAVE_Request | CollectionTypeAction_SAVE_Success | CollectionTypeAction_SAVE_Failure |
    CollectionTypeAction_DELETE_Request | CollectionTypeAction_DELETE_Success | CollectionTypeAction_DELETE_Failure | CollectionTypeAction_RELOAD |
    CollectionTypeAction_SHOW_DETAIL | CollectionTypeAction_CHANGE_SELECTED_IDS | CollectionTypeAction_SHOW_CONFIRM 