import * as Yup from 'yup';

//Chính ra nó là CollectionKeywordModel

export type CollectionItemModel = {
    id: number;
    collectID: number;
    name: string;
    link: string;
    stt: number;
}

export const dfCollectionItemModel: CollectionItemModel = {
    id: 0,
    collectID:0,
    name: '',
    link: '',
    stt: 0,
}

export const validCollectionItemSchema = () => Yup.object().shape({
    name: Yup.string().required('Vui lòng nhập tên từ khóa.'),
    collectID: Yup.number().required('Vui lòng nhập mã bộ sưu tập.'),
    link: Yup.string().required('Vui lòng nhập đường dẫn.'),
});