import { ApiBase } from "../../base/_ApiBase";
import { ApiClient } from "../../base/_ApiClient";
import { KeywordSeoModel } from "../../models/crm/KeywordSeoModel";

export const KeywordSeoApi = new class KeywordSeoApi extends ApiBase<KeywordSeoModel>{
    constructor({ baseUrl }: { baseUrl: string; }) {
        super(baseUrl);
    }
    GetKeywordBySearchPaginationAsync(request:any ) {
		return ApiClient.GET(`${this._baseUrl}/find${request ? `?${new URLSearchParams(request).toString()}` : ''}`);
	}
    GetAllKeywordAsync() {
		return ApiClient.GET(`${this._baseUrl}/all-keyword`);
	}
}({ baseUrl: '/crm' });