import { BasePaginationRespone } from '../../base/BasePaginationRespone';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { IActionTypeBase } from '../../base/IBaseActionTypes';
import { KeywordSeoModel } from '../../models/crm/KeywordSeoModel';

export enum eKeywordSeoActionTypeIds {
	GET_ALL_REQUEST = 'KeywordSeo_GET_ALL_REQUEST',
	GET_ALL_SUCCESS = 'KeywordSeo_GET_ALL_SUCCESS',
	GET_ALL_FAILURE = 'KeywordSeo_GET_ALL_FAILURE',

	GET_KEYWORD_REQUEST = 'KeywordSeo_GET_KEYWORD_REQUEST',
	GET_KEYWORD_SUCCESS = 'KeywordSeo_GET_KEYWORD_SUCCESS',
	GET_KEYWORD_FAILURE = 'KeywordSeo_GET_KEYWORD_FAILURE',

	NEED_RELOAD = 'KeywordSeo_NEED_RELOAD',

	CHANGE_SELECTED_IDS = 'KeywordSeo_CHANGE_SELECTED_IDS',
	SHOW_DETAIL = 'KeywordSeo_SHOW_DETAIL',
	SHOW_CONFIRM = 'KeywordSeo_SHOW_CONFIRM',
}

export interface KeywordAction_GETALL_Request extends IActionTypeBase<eKeywordSeoActionTypeIds.GET_ALL_REQUEST, undefined> {}
export interface KeywordAction_GETALL_Success extends IActionTypeBase<eKeywordSeoActionTypeIds.GET_ALL_SUCCESS, KeywordSeoModel[]> {}
export interface KeywordAction_GETALL_Failure extends IActionTypeBase<eKeywordSeoActionTypeIds.GET_ALL_FAILURE, Error | string> {}

export interface KeywordSeoAction_GETKEYWORD_Request extends IActionTypeBase<eKeywordSeoActionTypeIds.GET_KEYWORD_REQUEST, undefined> {}
export interface KeywordSeoAction_GETKEYWORD_Success
	extends IActionTypeBase<eKeywordSeoActionTypeIds.GET_KEYWORD_SUCCESS, KeywordSeoModel[]> {}
export interface KeywordSeoAction_GETKEYWORD_Failure
	extends IActionTypeBase<eKeywordSeoActionTypeIds.GET_KEYWORD_FAILURE, Error | string> {}

export interface KeywordSeoAction_RELOAD extends IActionTypeBase<eKeywordSeoActionTypeIds.NEED_RELOAD, undefined> {}

export interface KeywordSeoAction_SHOW_DETAIL
	extends IActionTypeBase<eKeywordSeoActionTypeIds.SHOW_DETAIL, BaseShowDetail<KeywordSeoModel>> {}
export interface KeywordSeoAction_CHANGE_SELECTED_IDS extends IActionTypeBase<eKeywordSeoActionTypeIds.CHANGE_SELECTED_IDS, number[]> {}
export interface KeywordSeoAction_SHOW_CONFIRM extends IActionTypeBase<eKeywordSeoActionTypeIds.SHOW_CONFIRM, boolean> {}

export type KeywordSeoActionTypes =
	| KeywordAction_GETALL_Request
	| KeywordAction_GETALL_Success
	| KeywordAction_GETALL_Failure
	| KeywordSeoAction_GETKEYWORD_Request
	| KeywordSeoAction_GETKEYWORD_Success
	| KeywordSeoAction_GETKEYWORD_Failure
	| KeywordSeoAction_RELOAD
	| KeywordSeoAction_SHOW_DETAIL
	| KeywordSeoAction_CHANGE_SELECTED_IDS
	| KeywordSeoAction_SHOW_CONFIRM;
