import { all, call, put, fork, takeEvery } from 'redux-saga/effects';
import { actions } from '../rootActions';
import {
	KeywordAction_DELETE_Request,
	KeywordAction_GETALL_Request,
	KeywordAction_GETPAGINATION_Request,
	KeywordAction_SAVE_Request,
	eKeywordActionTypeIds,
} from './IKeywordActionsTypes';
import { BaseResponse } from '../../base/BaseResponse';
import { rootApi } from '../../api/rootApi';
import { KeywordModel } from '../../models/keyword/KeywordModel';
import { KeywordResponse } from '../../models/keyword/KeywordResponse';

const _sagaApi = rootApi.keyword;

function* onLoadAllKeyword(action: KeywordAction_GETALL_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetKeywordByCategoryAsync],action.payload);
		if (res.isSuccess && res.result) {
			const listKeyword = res.result as KeywordResponse[];
			yield put(actions.keyword.getAllSuccess(listKeyword));
		} else {
			yield put(actions.keyword.getAllFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.keyword.getAllFailure(error || 'Có lỗi'));
	}
}

function* onLoadPaginationKeyword(action: KeywordAction_GETPAGINATION_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetPaginationAsync], action.payload);
		if (res.isSuccess && res.result) {
			const listKeyword = res.result as KeywordModel[];
			yield put(actions.keyword.getPaginationSuccess({ listDatas: listKeyword, pagination: res.pagination }));
		} else {
			yield put(actions.keyword.getPaginationFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.keyword.getPaginationFailure(error || 'Có lỗi'));
	}
}

function* onSaveKeyword(action: KeywordAction_SAVE_Request) {
	try {
		if (action.payload.id > 0) {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.keyword.saveSuccess(res.message));
				yield put(actions.keyword.needReload());
			} else {
				yield put(actions.keyword.saveFailure(res.message));
			}
		} else {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.keyword.saveSuccess(res.message));
				yield put(actions.keyword.needReload());
			} else {
				yield put(actions.keyword.saveFailure(res.message));
			}
		}
	} catch (error: any) {
		yield put(actions.keyword.saveFailure(error || 'Có lỗi'));
	}
}

function* onDeleteKeyword(action: KeywordAction_DELETE_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
		if (res.isSuccess) {
			yield put(actions.keyword.deleteSuccess(res.message));
			yield put(actions.keyword.needReload());
		} else {
			yield put(actions.keyword.deleteFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.keyword.deleteFailure(error || 'Có lỗi'));
	}
}

function* watchOnLoadAllKeyword() {
	yield takeEvery(eKeywordActionTypeIds.GET_ALL_REQUEST, onLoadAllKeyword);
}

function* watchOnLoadPaginationKeyword() {
	yield takeEvery(eKeywordActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationKeyword);
}

function* watchOnSaveKeyword() {
	yield takeEvery(eKeywordActionTypeIds.SAVE_REQUEST, onSaveKeyword);
}

function* watchOnDeleteKeyword() {
	yield takeEvery(eKeywordActionTypeIds.DELETE_REQUEST, onDeleteKeyword);
}

function* keywordSaga() {
	yield all([fork(watchOnLoadAllKeyword)]);
	yield all([fork(watchOnLoadPaginationKeyword)]);
	yield all([fork(watchOnSaveKeyword)]);
	yield all([fork(watchOnDeleteKeyword)]);
}

export default keywordSaga;
